import styled from 'styled-components';

export const HeaderWrapper = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 16px;
  grid-template-rows: 1fr;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  background-color: #fff;
  column-gap: 16px;
  border-radius: 10px;
  padding: 16px;
`;

export const BLogo = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
`;

export const BTitle = styled.h3`
  margin: 5px 0 0;
  font-size: 20px;
  font-weight: bold;
`;

export const Desc = styled.p`
  margin: 0;
  color: #707070;
  font-size: 16px;
  font-weight: 600;
`;

export const HRule = styled.hr`
  height: 1px;
  border: none;
  margin: 10px;
  background-color: #707070;
`;

export const Contacts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  column-gap: 20px;

  div {
    display: flex;
    align-items: center;
    column-gap: 6px;
    flex: 1;

    p {
      margin: 0;
      font-size: 16px;
    }
  }
`;

export const Scores = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;

  article {
    padding: 16px;
    border-radius: 10px;
    background-color: #fff8eb;
    text-align: center;
  }
`;
