import React, { useState } from "react";
import PageTitle from "../../Component/Comman/PageTitle";
import SurveyList from "../../Component/Survey/SurveyList";
import CreateSurvey from "../../Component/CreateSurvey/CreateSurvey";
import AddYourCoin from "../../Component/Academy/Courses/AddYourCoin";

function Survey() {
  const [displayCreate, setDisplayCreate] = useState(false);

  const createSurvey = () => {
    setDisplayCreate(true);
  };

  const closeForm = () => {
    setDisplayCreate(false);
  };

  return (
    <>
      <div className="body-header border-bottom d-flex py-3 mb-3">
        <PageTitle pagetitle="Survey" />
      </div>
      <div className="container-xxl">
        <div className="d-flex">
          {displayCreate ? (
            ""
          ) : (
            <button
              data-step-action="next"
              className="btn btn-primary step-btn"
              onClick={createSurvey}>
              Create survey
            </button>
          )}
        </div>
        <div className="row mb-3">
          {displayCreate ? <AddYourCoin onCreateCancel={closeForm} /> : ""}
        </div>
        <div className="row">
          {displayCreate ? <CreateSurvey /> : <SurveyList />}
        </div>
      </div>
    </>
  );
}

export default Survey;
