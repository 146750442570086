import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OtcPortalLimit from '../../Component/Trade/Convert/OtcPortalLimit';
import CountiesChart from '../Trade/Convert/CountiesChart';

function BalanceDetail() {
  return (
    <div className='row g-3 mb-3 row-deck'>
      <div className='col-xl-12 col-xxl-7'>
        <div className='card'>
          <Tab.Container defaultActiveKey='assets'>
            <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap'>
              <h6 className='mb-0 fw-bold'>Summary trends</h6>
              <Nav
                className='nav nav-tabs tab-body-header rounded d-inline-flex mt-2 mt-md-0'
                role='tablist'>
                <Nav.Item className='nav-item'>
                  <Nav.Link
                    className='nav-link '
                    eventKey='assets'
                    data-bs-toggle='tab'
                    href='#assets'
                    role='tab'>
                    Assets
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className='nav-item'>
                  <Nav.Link
                    className='nav-link'
                    eventKey='counties'
                    data-bs-toggle='tab'
                    href='#counties'
                    role='tab'>
                    Counties Coverage
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className='card-body'>
              <Tab.Content className='tab-content'>
                <Tab.Pane
                  className='tab-pane fade show'
                  id='assets'
                  eventKey='assets'>
                  <OtcPortalLimit />
                </Tab.Pane>
                <Tab.Pane
                  className='tab-pane fade'
                  id='Counties'
                  eventKey='counties'>
                  <CountiesChart />
                </Tab.Pane>
                <Tab.Pane
                  className='tab-pane fade'
                  id='Future'
                  eventKey='foruth'>
                  <OtcPortalLimit />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
      <div className='col-xl-12 col-xxl-5'>
        <div className='card'>
          <div className='card-header py-3 d-flex justify-content-between bg-transparent align-items-center'>
            <h6 className='mb-0 fw-bold'>Top Summary</h6>
            <Link
              to={process.env.PUBLIC_URL + '/security-page'}
              className='d-inline-flex'>
              <i className='icofont-caret-right fs-5'></i>
            </Link>
          </div>
          <div className='card-body'>
            <div className='row row-cols-2 g-0'>
              <div className='col'>
                <div className='security border-bottom border-end'>
                  <div className='d-flex align-items-start px-2 py-3'>
                    <div className='dot-green mx-2 my-2'></div>
                    <div className='d-flex flex-column'>
                      <span className='flex-fill text-truncate'>
                        Asset Applications Success Rate
                      </span>
                      <span>65%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='security border-bottom'>
                  <div className='d-flex align-items-start px-2 py-3'>
                    <div className='dot-red mx-2 my-2'></div>
                    <div className='d-flex flex-column'>
                      <span className='flex-fill text-truncate'>
                        SME Credit AVG Score
                      </span>
                      <span>760</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='security border-bottom border-end'>
                  <div className='d-flex align-items-start px-2 py-3'>
                    <div className='dot-red mx-2 my-2'></div>
                    <div className='d-flex flex-column'>
                      <span className='flex-fill text-truncate'>
                        Top Masterclass
                      </span>
                      <span>Marketing/Communications</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='security  border-bottom'>
                  <div className='d-flex align-items-start px-2 py-3'>
                    <div className='dot-green mx-2 my-2'></div>
                    <div className='d-flex flex-column'>
                      <span className='flex-fill text-truncate'>
                        Top Expert
                      </span>
                      <span>Melanie Keita</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BalanceDetail;
