import React from "react";
import { Container, CurrentWrapper, Label, Text } from "./styles";

function CurrentLoan() {
  return (
    <CurrentWrapper>
      <Container>
        <Label>Loan Amount:</Label>
        <Text>KSH 500,000</Text>
      </Container>
      <Container>
        <Label>Loan Purpose:</Label>
        <Text>BUSINESS CAPITAL FINANCING</Text>
      </Container>
      <Container>
        <Label>Loan Type:</Label>
        <Text>ASSET FINANCING</Text>
      </Container>
      <Container>
        <Label>Contract Period:</Label>
        <Text>24 MONTHS</Text>
      </Container>
      <Container>
        <Label>Application Date:</Label>
        <Text>JAN 28 2021</Text>
      </Container>
      <Container>
        <Label>Repayment Date:</Label>
        <Text>JAN 28 2023</Text>
      </Container>
    </CurrentWrapper>
  );
}

export default CurrentLoan;
