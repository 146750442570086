import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Badge from "react-bootstrap/Badge";
import {
  BSection,
  Category,
  HeaderWrapper,
  HRule,
  Logo,
  Text,
  TextChip,
  TextID,
  Title,
  TopSection,
} from "./styles";
import MKLogo from "../../../assets/images/mk_logo.svg";
import { formatDistanceToNow, parseISO } from "date-fns";
import { useAssignTicketMutation } from "../../../Redux/features/tickets/tickets";
import { Spinner } from "react-bootstrap";

function TicketHeader({ ticket }) {
  const ticketAssignees = ["Elijah", "Mr Gitonga", "Shaylene", "Kelvin"];
  const [tAssignee, setTAssignee] = useState(ticketAssignees[0]);
  const [assignTicket, { isLoading }] = useAssignTicketMutation();

  let status;
  let color;
  switch (ticket.status_id) {
    case 1:
      status = "open";
      color = "blue";
      break;
    case 2:
      status = "closed";
      color = "red";
      break;
    default:
      status = "pending";
      color = "#707070";
  }

  const onSetAssignee = async (assignee) => {
    setTAssignee(assignee);
    try {
      await assignTicket({ id: ticket.id, userId: 1 }).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  const buttonContent = isLoading ? (
    <button className="btn btn-primary step-btn">
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        className="me-1"
      />
    </button>
  ) : (
    <DropdownButton id="dropdown-basic-button" title="ESCALATE TO">
      {ticketAssignees.map((assignee) => (
        <Dropdown.Item key={assignee} onClick={() => onSetAssignee(assignee)}>
          {assignee}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );

  // noinspection JSValidateTypes
  return (
    <HeaderWrapper>
      <TopSection>
        <div className={"d-flex"}>
          <Logo src={MKLogo} />
          <div>
            <Title>EcoMakaa Enterprises</Title>
            <Badge pill bg="800" style={{ backgroundColor: color }}>
              {status}
            </Badge>
          </div>
        </div>
        <div>
          <TextID>#{ticket.id}</TextID>
          <Category>
            raised {formatDistanceToNow(parseISO(ticket.created_at))} ago
          </Category>
        </div>
      </TopSection>
      <HRule />
      <BSection>
        <div>
          <Category>Priority: </Category>
          <TextChip>{ticket.priority_id}</TextChip>
        </div>
        <div>
          <Category>Assignee: </Category>
          <Text>{ticket.assigned_to_user_id ?? "Unassigned"}</Text>
        </div>
        <div>
          <Category>Category: </Category>
          <Text>Technical Support</Text>
        </div>
        {buttonContent}
      </BSection>
    </HeaderWrapper>
  );
}

export default TicketHeader;
