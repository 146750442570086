import React from 'react';
import { useParams } from 'react-router-dom';
import BusinessHeader from '../../../Component/BusinessHeader/BusinessHeader';
import {
  BusinessWrapper,
  Header,
} from '../../../Component/LoanApplications/BusinessSummary/styles';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CompanyProfile from '../../../Component/LoanApplications/CompanyProfile/CompanyProfile';
import CompanyDocs from '../../../Component/LoanApplications/CompanyDocs/CompanyDocs';
import EntProfile from '../EntProfile';

function BusinessProfile() {
  const { id } = useParams();
  return (
    <>
      <BusinessHeader />
      <BusinessWrapper>
        <Header>Business Summary</Header>
        <Tabs
          defaultActiveKey='company-profile'
          id='justify-tab-example'
          className='mb-3'
          justify>
          {/*<Tab eventKey='entrepreneur-profile' title='Entrepreneur Profile'>*/}
          {/*  <EntProfile />*/}
          {/*</Tab>*/}
          <Tab eventKey='company-profile' title='Company Profile'>
            <CompanyProfile />
          </Tab>
          <Tab eventKey='company-documents' title='Company Documents'>
            <CompanyDocs />
          </Tab>
        </Tabs>
      </BusinessWrapper>
    </>
  );
}

export default BusinessProfile;
