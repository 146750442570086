import styled from "styled-components";

export const TextArea = styled.textarea`
  height: 150px;
  width: 100%;
  border-radius: 10px;
  background-color: #e6e6e6;
  border: 1px solid var(--border-color);
  margin: 16px 0;
  padding: 16px;
  outline: none;
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  color: #707070;
  transition: all 0.3s ease-in-out;
  padding: 12px 20px;

  h6 {
    text-transform: uppercase;
  }

  svg {
    margin: 0 0 16px;
    width: 20px;
    height: 20px;
    fill: var(--primary-color);
  }

  &.active {
    background-color: var(--primary-color);
    color: white;

    svg {
      fill: var(--primary-color);
    }
  }
`;
