import React, { useState } from "react";
import CreateLesson from "./CreateLesson";
import LessonsList from "./LessonsList";
import { useGetLessonsQuery } from "../../../Redux/features/lessons/lessonsApiSlice";
import { useParams } from "react-router-dom";

const Lesson = () => {
  const { id } = useParams();
  let table_row = [];
  const { isLoading, isSuccess, isError, error, data } = useGetLessonsQuery(id);

  if (isLoading) {
  } else if (isSuccess) {
    table_row = data.ids.map((id) => {
      return data.entities[id];
    });
  } else if (isError) console.log(error);
  const [displayCreate, setDisplayCreate] = useState(false);

  const createLesson = () => {
    setDisplayCreate(true);
  };

  const closeForm = () => {
    setDisplayCreate(false);
  };

  return (
    <>
      <div className="d-flex">
        {displayCreate ? (
          ""
        ) : (
          <button className="btn btn-primary" onClick={createLesson}>
            Add lesson
          </button>
        )}
      </div>
      <div className="row mb-3">
        {displayCreate ? (
          <CreateLesson courseId={id} onCreateCancel={closeForm} />
        ) : (
          ""
        )}
      </div>
      <div className="row">
        <LessonsList table_row={table_row} isLoading={isLoading} />
      </div>
    </>
  );
};

export default Lesson;
