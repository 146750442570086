export const FirstChartData = [
  {
    chartData: {
      options: {
        chart: {
          type: "area",
          height: 60,
          width: 424,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 2,
        },
        colors: ["#ffc107"],
      },
      series: [
        {
          data: [45, 36, 21, 16, 52, 82, 45, 36, 12, 61, 19, 52, 82],
        },
      ],
    },
  },
];

export const SecondChartData = [
  {
    chartData: {
      options: {
        chart: {
          type: "area",
          height: 60,
          width: 424,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 2,
        },
        colors: ["#0d6efd6e"],
      },
      series: [
        {
          data: [45, 36, 21, 16, 52, 82, 45, 36, 12, 61],
        },
      ],
    },
  },
];

export const ThirdChartData = [
  {
    chartData: {
      options: {
        chart: {
          type: "area",
          height: 60,
          width: 424,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 2,
        },
        colors: ["#c8e9ab"],
      },
      series: [
        {
          data: [16, 52, 82, 45, 36, 12, 61],
        },
      ],
    },
  },
];

export const FourthChartData = [
  {
    chartData: {
      options: {
        chart: {
          type: "area",
          height: 60,
          width: 424,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 2,
        },
        colors: ["#877f7ffc"],
      },
      series: [
        {
          data: [45, 36, 21, 45, 36, 12, 61],
        },
      ],
    },
  },
];
