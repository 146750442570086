import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { TICKETS_TAG } from "../../../constants";

const ticketsAdapter = createEntityAdapter();

const initialState = ticketsAdapter.getInitialState();

export const ticketsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAllTickets: build.query({
      query: () => `/ticket/list`,
      transformResponse: (rawResult) =>
        ticketsAdapter.setAll(initialState, rawResult?.response?.data),
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: TICKETS_TAG, id })),
              { type: TICKETS_TAG, id: "LIST" },
            ]
          : [{ type: TICKETS_TAG, id: "LIST" }],
    }),
    assignTicket: build.mutation({
      query: (args) => {
        const data = new FormData();
        data.append("user_id", args.userId);
        return {
          url: `/ticket/assign/${args.id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: TICKETS_TAG, id: arg.id },
      ],
    }),
    getTicketById: build.query({
      query: (id) => `/ticket/${id}`,
      transformResponse: (rawResult) => rawResult?.response,
    }),
    addComment: build.mutation({
      query: ({ content, id }) => {
        const formData = new FormData();
        formData.append("content", content);
        console.log("comment", formData);
        return {
          url: `/ticket/comment/${id}`,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useGetAllTicketsQuery,
  useAssignTicketMutation,
  useGetTicketByIdQuery,
  useAddCommentMutation,
} = ticketsApiSlice;
