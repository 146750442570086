export const getRows = (courses, categories) => {
  return courses.map((course) => {
    const category = categories.find((cg) => cg.id === course.category);
    return { ...course, category: category?.name };
  });
};

export const CourseColumns = () => {
  return [
    {
      name: "NAME",
      selector: (row) => row?.name,
      cell: (row) => row?.name,
    },
    {
      name: "CATEGORY",
      selector: (row) => row?.category,
      cell: (row) => row?.category,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row?.description,
    },
  ];
};
