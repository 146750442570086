import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().min(6, "Title is too short").required("Title is required"),
  description: yup
    .string()
    .min(6, "Description is too short")
    .required("Description is required"),
  category: yup.string().required("Category is required"),
  poster: yup.mixed().required("File is required"),
});
