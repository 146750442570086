import { Tab, Nav } from "react-bootstrap";
import React from "react";
import MarketTabing from "../../Component/Market/MarketTabing";
import PageTitle from "../../Component/Comman/PageTitle";

function Investors() {
  return (
    <>
      <div className="body-header border-bottom d-flex py-3 mb-3">
        <PageTitle pagetitle="Investors" />
      </div>
      <div className="container-xxl">
        <MarketTabing />
      </div>
    </>
  );
}

export default Investors;
