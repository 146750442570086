import React, { useState } from "react";
import { useSelector } from "react-redux";
import PageTitle from "../../Component/Comman/PageTitle";
import {
  selectAllFaqs,
  useGetFaqsQuery,
} from "../../Redux/features/faqs/faqsApiSlice";
import CreateFaq from "./CreateFaq";
import FaqList from "./FaqList";

const Faqs = () => {
  let table_row = [];
  const { isLoading, isSuccess, isError, error } = useGetFaqsQuery();
  const faqs = useSelector(selectAllFaqs);

  if (isSuccess) {
    table_row = faqs;
  } else if (isError) {
    console.log(error);
  }

  const [displayCreate, setDisplayCreate] = useState(false);

  const createFaq = () => {
    setDisplayCreate(true);
  };

  const closeForm = () => {
    setDisplayCreate(false);
  };

  return (
    <>
      <div className="body-header border-bottom d-flex py-3 mb-3">
        <PageTitle pagetitle="FAQS" />
      </div>
      <div className="container-xxl">
        {displayCreate ? (
          <div className="row mb-3">
            <CreateFaq onCreateCancel={closeForm} />
          </div>
        ) : (
          <div className="d-flex">
            <button className="btn btn-primary mb-2" onClick={createFaq}>
              Add Faq
            </button>
          </div>
        )}
        <div className="row">
          <FaqList table_row={table_row} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default Faqs;
