export const LESSONS_TAG = "Lessons";
export const COURSES_TAG = "courses";
export const CATEGORIES_TAG = "categories";
export const BUSINESSES_TAG = "businesses";
export const TICKETS_TAG = "tickets";
export const LOANS_TAG = "loans";

export const LOAN_PRODUCTS = "loan_products";

export const LOAN_TRANSACTIONS = "loan_transactions";

export const SURVEY_FORM = "survey";

export const SURVEY_RESULTS = "survey_results";

export const POSTS = "posts";

export const ROLES = "roles";

export const FAQS_TAG = "faqs";

export const EXPERTS_TAG = "experts";

export const MARKET_TAG = "marketplace";
