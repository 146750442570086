import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { schema } from "./create-lesson-schema";
import { useAddNewLessonMutation } from "../../../Redux/features/lessons/lessonsApiSlice";
import { Spinner, Toast } from "react-bootstrap";

const CreateLesson = ({ onCreateCancel, courseId }) => {
  const closeForm = () => {
    onCreateCancel(false);
  };
  const [addNewLesson, { isLoading, isSuccess }] = useAddNewLessonMutation();

  const [showToast, setShowToast] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmitHandler = async (data) => {
    const inputData = {
      ...data,
      course_id: courseId,
    };
    try {
      await addNewLesson({ ...inputData }).unwrap();
      reset();
      setErrMsg("A new lesson was added");
      setShowToast(true);
    } catch (e) {
      setErrMsg("Could not save lesson");
      setShowToast(true);
    }
  };

  const buttonContent = isLoading ? (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
      className="me-1"
    />
  ) : (
    "Create"
  );

  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0">Add lesson</h5>
          </div>
          <div className="card-body">
            <div className="wizard-main" id="w-horizontal">
              <div className="step-app">
                <div className="step-content">
                  <div className={`step-tab-panel active`} data-step="step1">
                    <form
                      onSubmit={handleSubmit(onSubmitHandler)}
                      id="create-course">
                      <div className="row">
                        <div className="col-md-4 col-12 mb-3">
                          <label className="col-form-label">Title</label>
                          <Form.Control
                            placeholder="Accounting fundamentals"
                            {...register("title")}
                          />
                          {errors.title?.message && (
                            <Form.Text>{errors.title?.message}</Form.Text>
                          )}
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                          <label className="col-form-label">Description</label>
                          <Form.Control
                            placeholder="Something..."
                            {...register("description")}
                          />
                          {errors.description?.message && (
                            <Form.Text>{errors.description?.message}</Form.Text>
                          )}
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                          <label className="col-form-label">Video Url</label>
                          <Form.Control
                            placeholder="https://www.vimeo/1233"
                            {...register("video_url")}
                          />
                          {errors.video_url?.message && (
                            <Form.Text>{errors.video_url?.message}</Form.Text>
                          )}
                        </div>
                        <div className="col-md-4 col-12 mb-3">
                          <label className="col-form-label">Duration</label>
                          <Form.Control
                            type={"number"}
                            placeholder="12"
                            {...register("duration")}
                          />
                          {errors.duration?.message && (
                            <Form.Text>{errors.duration?.message}</Form.Text>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="step-footer d-flex">
                  <button
                    className="btn btn-danger"
                    style={{ color: "white" }}
                    onClick={closeForm}>
                    Close
                  </button>
                  <button
                    disabled={!isDirty || !isValid}
                    type={"submit"}
                    form={"create-course"}
                    className="btn btn-primary step-btn">
                    {buttonContent}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={true}
        style={{ position: "absolute", top: "55px", right: "20px" }}
        className={`d-flex align-items-center text-white ${
          isSuccess ? "bg-success" : "bg-danger"
        } border-0 fade show`}>
        <Toast.Body>{errMsg}</Toast.Body>
        <button
          type="button"
          className="btn-close btn-close-white ms-auto me-2"
          data-dismiss="toast"
          aria-label="Close"
        />
      </Toast>
    </>
  );
};

export default CreateLesson;
