import React, { createRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { schema } from './create-expert-schema';
import { useAddExpertMutation } from '../../Redux/features/experts/expertsApiSlice';
import { Spinner, Toast } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function CreateExpert({ onCreateCancel }) {
  const [showToast, setShowToast] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const avatarRef = createRef();

  const [addExpert, { isLoading, isSuccess }] = useAddExpertMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmitHandler = async data => {
    const inputData = {
      password: 'password',
      role_type: 'exp',
      ...data,
    };
    try {
      await addExpert(inputData).unwrap();
      reset();
      setErrMsg('A new expert was added');
      setShowToast(true);
    } catch (e) {
      setErrMsg('Could not save expert');
      setShowToast(true);
    }
  };
  const buttonContent = isLoading ? (
    <Spinner
      as='span'
      animation='grow'
      size='sm'
      role='status'
      aria-hidden='true'
      className='me-1'
    />
  ) : (
    'Onboard expert'
  );
  const closeForm = () => {
    onCreateCancel(false);
  };
  return (
    <>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='card-header'>
            <h5 className='mb-0'>Onboard expert</h5>
          </div>
          <div className='card-body'>
            <div className='wizard-main' id='w-horizontal'>
              <div className='step-app'>
                <div className='step-content'>
                  <div className={`step-tab-panel active`} data-step='step1'>
                    <form
                      onSubmit={handleSubmit(onSubmitHandler)}
                      id='create-faq'>
                      <div className='row'>
                        <div className='col-md-6 col-12 mb-3'>
                          <Form.Label className='col-form-label'>
                            Expert name
                          </Form.Label>
                          <Form.Control
                            autoFocus={true}
                            autoCapitalize={'words'}
                            placeholder='Input expert name here'
                            {...register('name')}
                          />
                          {errors.name?.message && (
                            <Form.Text>{errors.name?.message}</Form.Text>
                          )}
                        </div>
                        <div className='col-md-6 col-12 mb-3'>
                          <Form.Label className='col-form-label'>
                            Email
                          </Form.Label>
                          <Form.Control
                            placeholder='expert@mail.com'
                            {...register('email')}
                          />
                          {errors.email?.message && (
                            <Form.Text>{errors.email?.message}</Form.Text>
                          )}
                        </div>
                        <div className='col-md-6 col-12 mb-3'>
                          <Form.Label className='col-form-label'>
                            Title
                          </Form.Label>
                          <Form.Control
                            placeholder="Input expert's title here"
                            {...register('title')}
                          />
                          {errors.title?.message && (
                            <Form.Text>{errors.title?.message}</Form.Text>
                          )}
                        </div>
                        <div className='col-md-6 col-12 mb-3'>
                          <Form.Label className='col-form-label'>
                            Company
                          </Form.Label>
                          <Form.Control
                            placeholder='Company name'
                            {...register('company')}
                          />
                          {errors.company?.message && (
                            <Form.Text>{errors.company?.message}</Form.Text>
                          )}
                        </div>
                        <div className='col-md-6 col-12 mb-3'>
                          <Form.Label className='col-form-label'>
                            Booking Link
                          </Form.Label>
                          <Form.Control
                            placeholder='Enter booking link'
                            {...register('booking_url')}
                          />
                          {errors.booking_url?.message && (
                            <Form.Text>{errors.booking_url?.message}</Form.Text>
                          )}
                        </div>
                        <div
                          className={'col-md-6 col-12 mb-3'}
                          style={{ marginTop: '5px' }}>
                          <Form.Label>Upload expert image</Form.Label>
                          <Form.Control
                            ref={avatarRef}
                            type='file'
                            name={'avatar'}
                            {...register('avatar')}
                          />
                          {errors.avatar?.message && (
                            <Form.Text>{errors.avatar?.message}</Form.Text>
                          )}
                        </div>
                        <div className='col-md-12 mb-3'>
                          <Form.Label className='col-form-label'>
                            Bio
                          </Form.Label>
                          <Form.Control
                            as={'textarea'}
                            rows={5}
                            placeholder='Type here...'
                            {...register('bio')}
                          />
                          {errors.bio?.message && (
                            <Form.Text>{errors.bio?.message}</Form.Text>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    className='btn btn-danger mx-5'
                    style={{ color: 'white' }}
                    onClick={closeForm}>
                    Close
                  </button>
                  <button
                    disabled={!isDirty || !isValid}
                    type={'submit'}
                    form={'create-faq'}
                    className='btn btn-primary'>
                    {buttonContent}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={true}
        style={{ position: 'absolute', top: '55px', right: '20px' }}
        className={`d-flex align-items-center text-white ${
          isSuccess ? 'bg-success' : 'bg-danger'
        } border-0 fade show`}>
        <Toast.Body>{errMsg}</Toast.Body>
        <button
          type='button'
          className='btn-close btn-close-white ms-auto me-2'
          data-dismiss='toast'
          aria-label='Close'
        />
      </Toast>
    </>
  );
}

export default CreateExpert;
