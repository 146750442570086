import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { schema } from "./create-lesson-schema";
import { Spinner, Toast } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useUpdateLessonMutation } from "../../../Redux/features/lessons/lessonsApiSlice";

function EditLesson({ onClose, show, lesson }) {
  const [updateLesson, { isLoading, isSuccess }] = useUpdateLessonMutation();
  const [showToast, setShowToast] = useState(false);
  const defaultData = {
    title: lesson.title,
    video_url: lesson.video_url,
    description: lesson.description,
    duration: lesson.duration,
  };

  const [errMsg, setErrMsg] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      video_url: "",
      description: "",
      duration: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    reset(defaultData);
  }, [lesson]);

  const onSubmitHandler = async (data) => {
    const inputData = {
      item: data,
      id: lesson.id,
    };
    try {
      await updateLesson({ ...inputData }).unwrap();
      setErrMsg("A new lesson was added");
      setShowToast(true);
      reset();
      onClose(false);
    } catch (e) {
      setErrMsg("Could not save lesson");
      setShowToast(true);
    }
  };

  const buttonContent = isLoading ? (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
      className="me-1"
    />
  ) : (
    "Update Lesson"
  );

  return (
    <>
      <Modal
        size="xl"
        show={show}
        onHide={() => onClose(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Edit Lesson
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmitHandler)} id={"edit-lesson"}>
            <div className="row">
              <div className="col-md-4 col-12 mb-3">
                <label className="col-form-label">Title</label>
                <Form.Control
                  placeholder="Accounting fundamentals"
                  {...register("title")}
                />
                {errors.title?.message && (
                  <Form.Text>{errors.title?.message}</Form.Text>
                )}
              </div>
              <div className="col-md-4 col-12 mb-3">
                <label className="col-form-label">Video Url</label>
                <Form.Control
                  placeholder="https://www.vimeo/1233"
                  {...register("video_url")}
                />
                {errors.video_url?.message && (
                  <Form.Text>{errors.video_url?.message}</Form.Text>
                )}
              </div>
              <div className="col-md-4 col-12 mb-3">
                <label className="col-form-label">Duration</label>
                <Form.Control
                  type={"number"}
                  placeholder="12"
                  {...register("duration")}
                />
                {errors.duration?.message && (
                  <Form.Text>{errors.duration?.message}</Form.Text>
                )}
              </div>
              <div className="col-md-6 col-12 mb-3">
                <label className="col-form-label">Description</label>
                <Form.Control
                  size={"lg"}
                  style={{ height: "100px" }}
                  placeholder="Something..."
                  as={"textarea"}
                  {...register("description")}
                />
                {errors.description?.message && (
                  <Form.Text>{errors.description?.message}</Form.Text>
                )}
              </div>
            </div>
          </form>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-danger me-3"
              style={{ color: "white" }}
              onClick={() => onClose(false)}>
              Cancel
            </button>
            <button
              disabled={!isDirty || !isValid}
              type={"submit"}
              form={"edit-lesson"}
              className="btn btn-primary">
              {buttonContent}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={true}
        style={{ position: "absolute", top: "55px", right: "20px" }}
        className={`d-flex align-items-center text-white ${
          isSuccess ? "bg-success" : "bg-danger"
        } border-0 fade show`}>
        <Toast.Body>{errMsg}</Toast.Body>
        <button
          type="button"
          className="btn-close btn-close-white ms-auto me-2"
          data-dismiss="toast"
          aria-label="Close"
        />
      </Toast>
    </>
  );
}

export default EditLesson;
