export const getPostRows = (posts, categories) => {
  const categoryMap = categories.reduce((acc, curr) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});

  return posts.map((post) => {
    const newPost = { ...post };
    newPost.category = categoryMap[post.category_id];
    delete newPost.category_id;
    return newPost;
  });
};

export const postColumns = () => {
  return [
    {
      name: "Title",
      selector: (row) => row?.summary,
      sortable: true,
    },
    {
      name: "CATEGORY",
      selector: (row) => row?.category,
      sortable: true,
    },
  ];
};
