import { LESSONS_TAG } from "../../../constants";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const lessonsAdapter = createEntityAdapter({
  selectId: (lesson) => lesson.id,
});
const initialState = lessonsAdapter.getInitialState();

const lessonApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLessons: build.query({
      query: (id) => `/lms/course/lessons/${id}`,
      transformResponse: (rawResult) => {
        return lessonsAdapter.setAll(initialState, rawResult?.response);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: LESSONS_TAG, id })),
              { type: LESSONS_TAG, id: "LIST" },
            ]
          : [{ type: LESSONS_TAG, id: "LIST" }],
    }),
    addNewLesson: build.mutation({
      query: (lesson) => {
        const formData = new FormData();
        for (const lessonKey in lesson) {
          formData.append(lessonKey, lesson[lessonKey]);
        }
        return {
          url: "/lms/lesson/create",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: LESSONS_TAG, id: "LIST" }],
    }),
    updateLesson: build.mutation({
      query: (lesson) => {
        const formData = new FormData();
        for (const lessonKey in lesson.item) {
          formData.append(lessonKey, lesson.item[lessonKey]);
        }
        return {
          url: `/lms/lesson/${lesson.id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: LESSONS_TAG, id: arg.id },
      ],
    }),
    deleteLesson: build.mutation({
      query: (id) => ({
        url: `/lms/lesson/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: LESSONS_TAG, id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetLessonsQuery,
  useAddNewLessonMutation,
  useDeleteLessonMutation,
  useUpdateLessonMutation,
} = lessonApiSlice;

export const selectLessonsResult =
  lessonApiSlice?.endpoints.getLessons.select();
const selectLessonsData = createSelector(
  selectLessonsResult,
  (lessonsResult) => lessonsResult?.data
);

export const { selectAll: selectAllLessons } = lessonsAdapter.getSelectors(
  (state) => selectLessonsData(state) ?? initialState
);
