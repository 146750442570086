import React from 'react';
import DataTable from 'react-data-table-component';
import { CourseColumns } from './CourseColumns';
import { useNavigate } from 'react-router-dom';
import { useDeleteCourseMutation } from '../../../Redux/features/courses/coursesApiSlice';
import { Spinner } from 'react-bootstrap';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';

const CoursesList = ({ table_row, isLoading }) => {
  const navigate = useNavigate();
  const [deleteCourse, { isLoading: loading }] = useDeleteCourseMutation();

  const handleShow = course => {
    navigate(`courses/${course.id}`);
  };

  const onDeleteCourse = async row => {
    try {
      await deleteCourse(row.id).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  const buttonContent = loading ? (
    <Spinner
      as='span'
      animation='grow'
      size='sm'
      role='status'
      aria-hidden='true'
      className='me-1'
    />
  ) : (
    'Delete Course'
  );

  const editButton = {
    name: 'ACTIONS',
    selector: () => {},
    sortable: true,
    cell: row => (
      <div className={'d-flex'}>
        <BiEdit
          onClick={() => handleShow(row)}
          style={{
            width: '25px',
            height: '25px',
            cursor: 'pointer',
            markerEnd: '55px',
            fill: '#81A1CA',
          }}
        />
        <MdDelete
          style={{
            width: '25px',
            height: '25px',
            cursor: 'pointer',
            marginLeft: '25px',
            fill: '#b02a37',
          }}
          onClick={() => onDeleteCourse(row)}
        />
      </div>
    ),
  };

  const deleteButton = {
    name: 'ACTION',
    selector: () => {},
    sortable: true,
    cell: row => (
      <div
        className='btn-group'
        role='group'
        aria-label='Basic outlined example'>
        <button
          type='submit'
          className='btn btn-light-danger'
          onClick={() => onDeleteCourse(row)}
          data-bs-toggle='modal'
          data-bs-target='#icoModal'>
          {buttonContent}
        </button>
      </div>
    ),
  };

  return (
    <div className='col-xl-12'>
      <div className='card no-bg'>
        <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0 align-items-center'>
          <h6 className='mb-0 fw-bold '>List of courses</h6>
        </div>
        <div className='card-body'>
          <div
            id='myProjectTable_wrapper'
            className='dataTables_wrapper dt-bootstrap5 no-footer'>
            <div className='row'>
              <div
                className='col-sm-12 col-md-6 mb-2 '
                style={{ display: 'flex', alignItems: 'center' }}>
                <div id='myDataTable_filter' className='dataTables_filter'>
                  <label className='d-flex align-items-center'>
                    Search:
                    <div className='mx-1'>
                      <input type='search' className='form-control' />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <DataTable
                  columns={[...CourseColumns(), editButton]}
                  data={table_row}
                  onRowClicked={row => handleShow(row)}
                  defaultSortField='title'
                  pagination
                  selectableRows={false}
                  highlightOnHover={true}
                  pointerOnHover={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<Modal
        className="modal fade"
        id="icoModal"
        show={isEditModal}
        onHide={() => {
          setIsEditModal(false);
        }}
        fullscreen={fullscreen}>
        <Modal.Dialog className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <Modal.Header className="modal-header" closeButton>
              <h5 className="modal-title">Course lessons</h5>
            </Modal.Header>
            <Modal.Body className="modal-body custom_setting">
              <div className="d-flex">
                {displayCreateLesson ? (
                  ""
                ) : (
                  <button
                    data-step-action="next"
                    className="btn btn-primary step-btn mb-3"
                    onClick={createLesson}>
                    Create lesson
                  </button>
                )}
              </div>
              {displayCreateLesson ? <CreateLesson /> : ""}
            </Modal.Body>
          </div>
        </Modal.Dialog>
      </Modal>*/}
    </div>
  );
};
export default CoursesList;
