import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { MARKET_TAG } from "../../../constants";

const marketplaceAdapter = createEntityAdapter();
const initialState = marketplaceAdapter.getInitialState();

const marketplaceApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getApps: build.query({
      query: () => "/business/app/list",
      transformResponse: (rawResult) =>
        marketplaceAdapter.setAll(initialState, rawResult?.response?.data),
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: MARKET_TAG, id }))]
          : [{ type: MARKET_TAG, id: "LIST" }],
    }),
    addApp: build.mutation({
      query: (item) => {
        const appData = new FormData();
        for (const itemKey in item) {
          appData.append(itemKey, item[itemKey]);
        }
        return {
          url: "/business/app/create",
          method: "POST",
          body: appData,
        };
      },
    }),
  }),
});

export const { useGetAppsQuery, useAddAppMutation } = marketplaceApiSlice;

const selectAppsResult = marketplaceApiSlice?.endpoints?.getApps.select();
const selectAppsData = createSelector(
  selectAppsResult,
  (appsResult) => appsResult?.data
);

export const { selectAll: selectAllApps } = marketplaceAdapter.getSelectors(
  (state) => selectAppsData(state) ?? initialState
);
