import React, { useState } from "react";
import {
  CommentsSection,
  MainContent,
  SingleTicketWrapper,
  Title,
} from "./styles";
import TicketHeader from "../../Component/Ticket/TicketHeader/TicketHeader";
import TicketSubject from "../../Component/Ticket/TicketSubject/TicketSubject";
import Comments from "../../Component/Ticket/Comments/Comments";
import { SubHeader, TArea } from "../../Component/Ticket/TicketSubject/styles";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import {
  useAddCommentMutation,
  useGetAllTicketsQuery,
} from "../../Redux/features/tickets/tickets";

const SingleTicket = () => {
  const { id } = useParams();
  const [comment, setComment] = useState("");

  const {
    ticket,
    isLoading: isTicketLoading,
    isSuccess: isTicketSuccess,
    isError: isTicketError,
    error: ticketError,
  } = useGetAllTicketsQuery("getAllTickets", {
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => ({
      ticket: data?.entities[id],
      isLoading,
      isSuccess,
      isError,
      error,
    }),
  });

  const [addComment, { isLoading }] = useAddCommentMutation();

  const onSubmitComment = async () => {
    try {
      await addComment({ content: comment, id: ticket.id }).unwrap();
      setComment("");
    } catch (e) {
      console.log(e);
    }
  };

  let content;
  if (isTicketLoading) {
    content = <p>Loading...</p>;
  } else if (isTicketSuccess) {
    content = (
      <SingleTicketWrapper>
        <MainContent>
          <Title>Ticket Information</Title>
          <TicketHeader ticket={ticket} />
          <TicketSubject ticket={ticket} />
          <SubHeader className={"mt-3"}>Write a comment</SubHeader>
          <TArea value={comment} onChange={(e) => setComment(e.target.value)} />
          <div className="text-end w-100">
            <Button
              onClick={onSubmitComment}
              disabled={!comment.length > 0 || isLoading}
              className={"fw-bold text-uppercase text-dark p-3"}>
              Submit Comment
            </Button>
          </div>
        </MainContent>
        <CommentsSection>
          <Comments />
        </CommentsSection>
      </SingleTicketWrapper>
    );
  } else if (isTicketError) {
    console.log(ticketError);
    content = <p>Something went wrong!</p>;
  }

  return content;
};

export default SingleTicket;
