import styled from "styled-components";

export const HistoryWrapper = styled.section`
  margin-top: 20px;

  .paginate {
    display: flex;
    justify-content: center;
    column-gap: 8px;
    align-items: center;
    list-style: none !important;

    .page {
      cursor: pointer;
      color: #636363;
      border: 1px solid #707070;
      border-radius: 5px;
      padding: 2px 5px;
      transition: 0.2s ease-in-out all;
    }

    .page_active {
      background-color: var(--primary-color);
      color: white;
      border-color: var(--primary-color);
    }
  }

  td {
    padding: 0;
    margin: auto 0;
  }
`;

export const THead = styled.th`
  text-transform: uppercase;
  color: #707070;
`;

export const TDStatus = styled.td`
  div {
    text-align: center;
    border: 1px solid orange;
    border-radius: 50px;
    padding: 5px;
  }
`;
