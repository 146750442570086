import React, {useState} from 'react';
import Chart from 'react-apexcharts';

function CountiesChart() {
    const [options, setOptions] = useState({
        chart: {
            height: 380, type: 'donut', labels: []
        }, plotOptions: {
            pie: {
                startAngle: -90, endAngle: 270,
            },
        }, dataLabels: {
            enabled: true, style: {
                colors: ['#ffffff']
            },
            background: {
                enabled: false
            },
            dropShadow: {
                enabled: false
            }
        }, fill: {
            type: 'gradient',
        }, legend: {
            formatter: function (val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
            },
        }, title: {
            text: '',
        }, responsive: [{
            breakpoint: 480, options: {
                chart: {
                    width: 200,
                }, legend: {
                    position: 'bottom',
                },
            },
        },], labels: ['Nairobi', 'Kiambu', 'Nakuru', 'Kakamega', 'Uasin Gishu', 'Bungoma',]
    });

    const [series, setSeries] = useState([176, 98, 126, 48, 9, 43]);
    return (<>
        <div className='col-xl-12' style={{position: 'relative'}}>
            <Chart
                options={options}
                series={series}
                type='donut'
                width={380}
            />
        </div>
    </>);
}

export default CountiesChart;
