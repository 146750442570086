// noinspection JSValidateTypes

import React from 'react';
import {
  Button,
  CHeader,
  ChecklistWrapper,
  Chip,
  Container,
  Details,
  Doc,
  DocContainer,
  DocDetail,
  DocWrapper,
  Header,
  Num,
  SaveBtn,
  Wrapper,
} from './styles';
import axios from 'axios';
import fileDownload from 'js-file-download';

function CompanyDocs() {
  const docs = [
    { name: 'tax_document', isChecked: true },
    { name: 'vat_document', isChecked: false },
    { name: 'business_permit', isChecked: true },
    { name: 'doc 4', isChecked: false },
    { name: 'doc 5', isChecked: true },
  ];

  const handleClick = () => {
    window.open(
      'https://api.melaninkapital.com//storage/5/sample.pdf',
      '_blank'
    );
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then(res => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <div className={'p-2'}>
      <Header>
        <hr />
        <h6>Attached Documents</h6>
        <hr />
      </Header>
      <Wrapper>
        <Num>
          <p>1</p>
        </Num>
        <Container>
          <Doc />
          <Details>
            <p>registration_certificate</p>
            <p className='info'>
              <span>10mb</span> <span>Jan 12 2023</span>
            </p>
          </Details>
          <Button onClick={handleClick}>View</Button>
          <Button
            className={'download'}
            onClick={() =>
              handleDownload(
                'https://api.melaninkapital.com//storage/5/sample.pdf',
                'air.png'
              )
            }>
            Download
          </Button>
        </Container>
      </Wrapper>
      <Header className={'checklist'}>
        <hr />
        <h6>Documents Checklist</h6>
        <hr />
      </Header>
      <ChecklistWrapper>
        <CHeader>
          <p>Document name</p>
          <p>Status</p>
        </CHeader>
        {docs.map((doc, index) => (
          <DocWrapper key={doc.name}>
            <Num>
              <p>{index + 1}</p>
            </Num>
            <DocContainer>
              <input type='checkbox' />
              <DocDetail>{doc.name}</DocDetail>
              <Chip isChecked={doc.isChecked}>
                {doc.isChecked ? 'Verified' : 'Pending'}
              </Chip>
            </DocContainer>
          </DocWrapper>
        ))}
        <SaveBtn>
          <button>Save Changes</button>
        </SaveBtn>
      </ChecklistWrapper>
    </div>
  );
}

export default CompanyDocs;
