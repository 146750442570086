import React from 'react';
import DataTable from 'react-data-table-component';
import {
  selectAllBusinesses,
  useGetBusinessesQuery,
} from '../../Redux/features/business/businessSlice';
import { useSelector } from 'react-redux';
import { businessColumns } from './businessColumns';
import { defaultEntrepreneurs } from '../../utils/data';
import { useNavigate } from 'react-router-dom';

const BusinessList = () => {
  /*const { isLoading, isSuccess, isError, error } = useGetBusinessesQuery();
        const businesses = useSelector(selectAllBusinesses);*/
  const navigate = useNavigate();

  let table_row = defaultEntrepreneurs;

  const handleShow = row => {
    navigate(`${row.id}`);
  };

  return (
    <div className='col-xl-12'>
      <div className='card no-bg'>
        <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0 align-items-center'>
          <h6 className='mb-0 fw-bold '>List of businesses in portfolio</h6>
        </div>
        <div className='card-body'>
          <div
            id='myProjectTable_wrapper'
            className='dataTables_wrapper dt-bootstrap5 no-footer'>
            <div className='row'>
              <div
                className='col-sm-12 col-md-6 mb-2'
                style={{ display: 'flex', alignItems: 'center' }}>
                <div id='myDataTable_filter' className='dataTables_filter'>
                  <label className='d-flex align-items-center'>
                    Search:
                    <div className='mx-1'>
                      <input type='search' className='form-control' />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <DataTable
                  columns={[...businessColumns()]}
                  data={table_row}
                  onRowClicked={row => handleShow(row)}
                  defaultSortField='title'
                  pagination
                  selectableRows={false}
                  highlightOnHover={true}
                  pointerOnHover={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<Modal
        className="modal fade"
        id="icoModal"
        show={isEditModal}
        onHide={() => {
          setIsEditModal(false);
        }}
        fullscreen={fullscreen}>
        <Modal.Dialog className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <Modal.Header className="modal-header" closeButton>
              <h5 className="modal-title">Course lessons</h5>
            </Modal.Header>
            <Modal.Body className="modal-body custom_setting">
              <div className="d-flex">
                {displayCreateLesson ? (
                  ""
                ) : (
                  <button
                    data-step-action="next"
                    className="btn btn-primary step-btn mb-3"
                    onClick={createLesson}>
                    Create lesson
                  </button>
                )}
              </div>
              {displayCreateLesson ? <CreateLesson /> : ""}
            </Modal.Body>
          </div>
        </Modal.Dialog>
      </Modal>*/}
    </div>
  );
};

export default BusinessList;
