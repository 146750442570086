import React from "react";

export const lessonColumns = () => {
  return [
    {
      name: "NAME",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "DURATION",
      selector: (row) => row.duration,
    },
  ];
};
