import { apiSlice } from '../../app/api/apiSlice';
import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { EXPERTS_TAG } from '../../../constants';

const expertsAdapter = createEntityAdapter();
const initialState = expertsAdapter.getInitialState();

const expertsApiSlice = apiSlice.injectEndpoints({
  endpoints: build => ({
    getExperts: build.query({
      query: () => '/all/expert',
      transformResponse: rawResult => {
        console.log(rawResult?.response);
        return expertsAdapter.setAll(initialState, rawResult?.response);
      },
      providesTags: result =>
        result
          ? [...result.ids.map(id => ({ type: EXPERTS_TAG, id }))]
          : [{ type: EXPERTS_TAG, id: 'LIST' }],
    }),
    addExpert: build.mutation({
      query: item => {
        const expertData = new FormData();
        for (const itemKey in item) {
          expertData.append(itemKey, item[itemKey]);
        }
        return {
          url: '/signup',
          method: 'POST',
          body: expertData,
        };
      },
      invalidatesTags: [{ type: EXPERTS_TAG, id: 'LIST' }],
    }),
  }),
});

export const { useGetExpertsQuery, useAddExpertMutation } = expertsApiSlice;

const selectExpertsResult = expertsApiSlice?.endpoints?.getExperts.select();
const selectExpertsData = createSelector(
  selectExpertsResult,
  expertsResult => expertsResult?.data
);
export const { selectAll: selectAllExperts } = expertsAdapter.getSelectors(
  state => selectExpertsData(state) ?? initialState
);
