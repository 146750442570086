import React, { useState } from "react";
import CreateCourseFrom from "./CreateCourseFrom";
import CoursesList from "./CoursesList";
import {
  selectAllCourses,
  useGetCoursesQuery,
} from "../../../Redux/features/courses/coursesApiSlice";
import {
  selectAllCategories,
  useGetCategoriesQuery,
} from "../../../Redux/features/categories/categoriesSlice";
import { useSelector } from "react-redux";
import { getRows } from "./CourseColumns";

function Courses() {
  const [displayCreate, setDisplayCreate] = useState(false);
  const { isLoading, isSuccess, isError, error } = useGetCoursesQuery();
  const {
    isLoading: loading,
    isSuccess: success,
    isError: isCategoryError,
    error: categoryError,
  } = useGetCategoriesQuery();

  const courses = useSelector(selectAllCourses);
  const categories = useSelector(selectAllCategories);

  let table_row = [];
  if (isLoading || loading) {
  } else if (isSuccess && success) {
    table_row = getRows(courses, categories);
  } else if (isError || isCategoryError) {
    console.log(error);
    console.log(categoryError);
  }

  const createCourse = () => {
    setDisplayCreate(true);
  };

  const closeForm = () => {
    setDisplayCreate(false);
  };
  return (
    <>
      <div className="d-flex">
        {displayCreate ? (
          ""
        ) : (
          <button
            data-step-action="next"
            className="btn btn-primary step-btn"
            onClick={createCourse}>
            Create course
          </button>
        )}
      </div>
      <div className="row mb-3">
        {displayCreate ? (
          <CreateCourseFrom
            categories={categories}
            onCreateCancel={closeForm}
          />
        ) : (
          ""
        )}
      </div>
      <div className="row">
        <CoursesList table_row={table_row} isLoading={isLoading || loading} />
      </div>
    </>
  );
}

export default Courses;
