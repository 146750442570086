import * as yup from "yup";

export const schema = yup.object().shape({
  question: yup
    .string()
    .min(6, "Question is too short")
    .required("Question is required"),
  answer: yup
    .string()
    .min(6, "Answer is too short")
    .required("Answer is required"),
});
