import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthHeader from "../Component/Comman/AuthHeader";
import Signin from "../Component/Auth/SignIn";
import Signup from "../Component/Auth/Signup";
import ForgotPassword from "../Component/Auth/ForgotPassword";
import Verification from "../Component/Auth/Verification";
import ErrorPage from "../Component/Auth/ErrorPage";

function AuthIndex(props) {
  const baseUrl = process.env.PUBLIC_URL;
  return (
    <div className="main p-2 py-3 p-xl-5">
      <AuthHeader />
      <Routes>
        <Route exact path={baseUrl + "/sign-in"} element={<Signin />} />
        <Route exact path={baseUrl + "/sign-up"} element={<Signup />} />
        <Route
          exact
          path={baseUrl + "/forgot-password"}
          element={<ForgotPassword />}
        />
        <Route
          exact
          path={baseUrl + "/verification"}
          element={<Verification />}
        />
        <Route exact path={baseUrl + "/404page"} element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default AuthIndex;
