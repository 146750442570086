import React, { useState } from "react";
import { Spinner, Tab, Toast } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AuthRight from "./AuthRight";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { schema } from "./signup-schema";
import Form from "react-bootstrap/Form";
import { useRegisterUserMutation } from "../../Redux/features/auth/authApiSlice";

const Signup = () => {
  const [showToast, setShowToast] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const navigate = useNavigate();

  const [registerUser, { isLoading }] = useRegisterUserMutation();
  const onSubmitHandler = async (data) => {
    const userInput = {
      name: `${data.firstName} ${data.lastName}`,
      password: data.password,
      email: data.email,
    };
    try {
      await registerUser({ ...userInput }).unwrap();
      navigate(`${process.env.PUBLIC_URL}/sign-in`);
    } catch (err) {
      if (err.data.response.errors.email.length > 0) {
        setErrMsg(err.data.response.errors.email[0]);
        setShowToast(true);
      } else {
        setErrMsg("Something went wrong!");
        setShowToast(true);
      }
    }
  };

  const buttonContent = isLoading ? (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
      className="me-1"
    />
  ) : (
    "create account"
  );

  return (
    <>
      <div className="body d-flex p-0 p-xl-5">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-6 d-flex justify-content-center align-items-center auth-h100">
              <div className="d-flex flex-column">
                <h1>Create Your Account</h1>
                <span className="text-muted">Register with your email</span>
                <Tab.Container defaultActiveKey="first">
                  <Tab.Content className="tab-content mt-4 mb-3">
                    <Tab.Pane
                      className="tab-pane fade"
                      id="Email"
                      eventKey="first">
                      <div className="card">
                        <div className="card-body p-4">
                          <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <div className="mb-2">
                              <Form.Label className="fs-6" htmlFor="firstName">
                                First Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="firstName"
                                aria-describedby="firstNameHelpBlock"
                                {...register("firstName")}
                              />
                              {errors.firstName?.message && (
                                <Form.Text
                                  id="firstNameHelpBlock"
                                  muted
                                  className={"text-danger"}>
                                  {errors.firstName?.message}
                                </Form.Text>
                              )}
                            </div>
                            <div className="mb-2">
                              <Form.Label
                                className="fs-6"
                                htmlFor="inputLastName">
                                Last Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="inputLastName"
                                aria-describedby="lastNameHelpBlock"
                                {...register("lastName")}
                              />
                              {errors.lastName?.message && (
                                <Form.Text
                                  id="lastNameBlock"
                                  muted
                                  className={"text-danger"}>
                                  {errors.lastName?.message}
                                </Form.Text>
                              )}
                            </div>
                            <div className="mb-2">
                              <Form.Label className="fs-6" htmlFor="inputEmail">
                                Email address
                              </Form.Label>
                              <Form.Control
                                type="email"
                                id="inputEmail"
                                aria-describedby="emailHelpBlock"
                                {...register("email")}
                              />
                              {errors.email?.message && (
                                <Form.Text
                                  id="emailHelpBlock"
                                  muted
                                  className={"text-danger"}>
                                  Enter a valid email address
                                </Form.Text>
                              )}
                            </div>
                            <div className="mb-2">
                              <Form.Label
                                className="fs-6"
                                htmlFor="inputPassword">
                                Password
                              </Form.Label>
                              <Form.Control
                                {...register("password")}
                                type="password"
                                aria-describedby="passwordHelpBlock"
                                id={"inputPassword"}
                              />
                              {errors.password?.message && (
                                <Form.Text
                                  id="passwordHelpBlock"
                                  muted
                                  className={"text-danger"}>
                                  {errors.password?.message}
                                </Form.Text>
                              )}
                            </div>
                            <div className="mb-2">
                              <Form.Label
                                className="fs-6"
                                htmlFor="inputConfirmPassword">
                                Confirm Password
                              </Form.Label>
                              <Form.Control
                                {...register("confirmPassword")}
                                type="password"
                                aria-describedby="confirmPasswordHelpBlock"
                                id={"inputConfirmPassword"}
                              />
                              {errors.confirmPassword?.message && (
                                <Form.Text
                                  id="confirmPasswordHelpBlock"
                                  muted
                                  className={"text-danger"}>
                                  {errors.confirmPassword?.message}
                                </Form.Text>
                              )}
                            </div>
                            <button
                              disabled={!isDirty || !isValid}
                              type="submit"
                              className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">
                              {buttonContent}
                            </button>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
                <Link to={process.env.PUBLIC_URL + "/sign-in"} title="#">
                  Already registered?{" "}
                  <span className="text-secondary text-decoration-underline">
                    Log In
                  </span>
                </Link>
              </div>
            </div>
            <AuthRight />
          </div>
        </div>
      </div>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={true}
        style={{ position: "absolute", top: "55px", right: "20px" }}
        className="d-flex align-items-center text-white bg-danger border-0 fade show">
        <Toast.Body>{errMsg}</Toast.Body>
        <button
          type="button"
          className="btn-close btn-close-white ms-auto me-2"
          data-dismiss="toast"
          aria-label="Close"
        />
      </Toast>
    </>
  );
};

export default Signup;
