import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, 'App name is too short')
    .required('App name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  title: yup.string().min(6, 'Title is too short'),
  company: yup.string().required('Company is required'),
  bio: yup.string().required('Bio is required'),
  booking_url: yup.string().required('Booking url is required'),
  avatar: yup.mixed().required('File is required'),
});
