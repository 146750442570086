import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { ButtonWrapper, PButton } from "./styles";
import CurrentLoan from "../CurrentLoan/CurrentLoan";
import LoanHistory from "../LoanHistory/LoanHistory";
import LoanSummary from "../LoanSummary/LoanSummary";
import LoanComments from "../LoanComments/LoanComments";

function LoanDetails() {
  const [option, setOption] = useState(1);

  let content;
  if (option === 1) content = <CurrentLoan />;
  else if (option === 2) content = <LoanHistory />;
  else content = <LoanComments />;
  return (
    <div>
      <LoanSummary />
      <ButtonWrapper>
        <PButton
          className={`${option === 1 ? "active" : ""}`}
          onClick={() => setOption(1)}>
          Current Loan
        </PButton>
        <PButton
          className={`${option === 2 ? "active" : ""}`}
          onClick={() => setOption(2)}>
          Loan History
        </PButton>
        <PButton
          className={`${option === 3 ? "active" : ""}`}
          onClick={() => setOption(3)}>
          Loan Comments
        </PButton>
      </ButtonWrapper>
      {content}
      <div className="d-flex text-end w-100 mt-5 justify-content-end">
        <Button
          style={{ backgroundColor: "#707070", border: "none" }}
          className={"text-uppercase text-light p-3 mx-5"}>
          Raise Ticket
        </Button>
        <Button
          style={{ backgroundColor: "#018158", border: "none" }}
          className={"text-uppercase text-light p-3"}>
          Continue
        </Button>
      </div>
    </div>
  );
}

export default LoanDetails;
