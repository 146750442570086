import React from "react";
import BadgesTile from "../../Component/Ui Component/BadgesTile";

function Badges (){
    return(
        <div className="container">
            <BadgesTile />
        </div>
    )
  }
export default Badges;