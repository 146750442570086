import styled from "styled-components";

export const CommentsWrapper = styled.section`
  background-color: #fff8eb;
  padding: 16px;
  margin-top: 48px;
  font-family: "Montserrat", sans-serif;

  .header {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  p {
    margin: 0 !important;
  }
`;

export const Header = styled.h3`
  font-weight: 600;
  font-size: 18px;
  margin: 0;
`;

export const Count = styled.div`
  background-color: #ffab00;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  display: flex;
  width: 32px;
  height: 32px;
`;

export const HRuleMain = styled.hr`
  background-color: #707070;
  height: 1px;
  margin: 10px -16px;
  border: none;
`;

export const HRule = styled(HRuleMain)`
  margin: 10px 0;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
`;

export const Username = styled.p`
  font-weight: 600;
  font-size: 14px;
`;

export const Duration = styled(Username)`
  font-size: 14px;
  font-weight: normal;
  color: #707070;
`;

export const Text = styled.p`
  font-size: 13px;
`;

export const SingleComment = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: flex-start;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
