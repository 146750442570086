import * as yup from "yup";

export const schema = yup.object().shape({
  content: yup
    .string()
    .min(6, "Title is too short")
    .required("Title is required"),
  summary: yup
    .string()
    .min(6, "Description is too short")
    .required("Description is required"),
  category_id: yup.string().required("Category is required"),
});
