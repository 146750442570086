import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import EditFaq from '../Faqs/EditFaq';
import { expertsColumns } from './expertsColumns';
import Button from 'react-bootstrap/Button';

function ExpertsList({ isLoading, table_row }) {
  const [isEditModal, setIsEditModal] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState({});
  const [deleteRow, setDeleteRow] = useState({});
  const handleShow = row => {
    setSelectedExpert(row);
  };

  const onDeleteExpert = async row => {
    setDeleteRow(row);
    try {
    } catch (e) {
      setDeleteRow({});
      console.log(e);
    }
  };

  const buttonContent = () => {
    return 'Delete';
  };

  const bookLink = {
    name: 'Booking',
    selector: () => {},
    cell: row => (
      <Button href={row.booking_url} variant='link'>
        Link
      </Button>
    ),
  };

  const editButton = {
    name: 'Edit',
    selector: () => {},
    cell: row => (
      <button className='btn btn-light-success' onClick={() => handleShow(row)}>
        Edit
      </button>
    ),
  };

  const deleteButton = {
    name: 'Delete',
    selector: () => {},
    cell: row => (
      <button
        className='btn btn-light-danger'
        onClick={() => onDeleteExpert(row)}>
        {buttonContent(row)}
      </button>
    ),
  };

  return (
    <>
      <div className='col-xl-12'>
        <div className='card no-bg'>
          <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0 align-items-center'>
            <h6 className='mb-0 fw-bold'>List of experts</h6>
          </div>
          <div className='card-body'>
            <div
              id='myProjectTable_wrapper'
              className='dataTables_wrapper dt-bootstrap5 no-footer'>
              <div className='row'>
                <div className='col-sm-12'>
                  <DataTable
                    columns={[
                      ...expertsColumns(),
                      bookLink,
                      editButton,
                      deleteButton,
                    ]}
                    data={table_row}
                    pagination
                    selectableRows={false}
                    highlightOnHover={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditFaq show={false} onClose={setIsEditModal} post={selectedExpert} />
    </>
  );
}

export default ExpertsList;
