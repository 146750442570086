import styled from "styled-components";

export const HeaderWrapper = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 16px;

  p {
    margin: 0 !important;
  }
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .d-flex {
    justify-content: flex-start;
  }
`;

export const Logo = styled.img`
  border-radius: 50%;
  object-fit: contain;
  width: 40px;
  height: 40px;
  margin-right: 5px;
`;

export const Title = styled.h3`
  font-size: 20px;
`;

export const Status = styled.p`
  padding: 6px 3px;
  text-align: center;
  border-radius: 50px;
  color: white;
  background-color: #707070;
  margin: 0 !important;
  align-self: flex-start !important;
  justify-self: flex-start;
`;
export const HRule = styled.hr`
  border: none;
  background-color: #707070;
  height: 1px;
  margin: 12px -16px;
`;

export const BSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Category = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

export const Text = styled.p`
  font-size: 16px;
`;

export const TextChip = styled.p`
  font-size: 16px;
  padding: 1px 12px;
  text-align: center;
  border: 1px solid orange;
  border-radius: 50px;
  margin: 0 !important;
  width: max-content;
`;

export const Button = styled.button`
  background-color: orange;
  border-radius: 10px;
  border: none;
  padding: 5px 8px;
  outline: none;
  font-size: 16px;
`;

export const TextID = styled.p`
  font-size: 16px;
  color: #707070;
  text-align: right;
`;
