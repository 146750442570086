import React from "react";
import AlertTile from "../../Component/Ui Component/AlertTile";

function Alerts () {
    return(
        <div className="container">
            <AlertTile />
        </div>
    )
  }

  
export default Alerts;