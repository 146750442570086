import React from 'react';
import { LoanWrapper } from './styles';
import BusinessHeader from '../../Component/BusinessHeader/BusinessHeader';
import BusinessSummary from '../../Component/LoanApplications/BusinessSummary/BusinessSummary';
import { useParams } from 'react-router-dom';

const LoanApplications = () => {
  return (
    <LoanWrapper>
      <BusinessHeader />
      <BusinessSummary />
    </LoanWrapper>
  );
};

export default LoanApplications;
