import { COURSES_TAG } from "../../../constants";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const coursesAdapter = createEntityAdapter();

const initialState = coursesAdapter.getInitialState();

const coursesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCourses: build.query({
      query: () => "/lms/course/list",
      transformResponse: (rawResult) => {
        return coursesAdapter.setAll(initialState, rawResult?.response?.data);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: COURSES_TAG, id })),
              { type: COURSES_TAG, id: "LIST" },
            ]
          : [{ type: COURSES_TAG, id: "LIST" }],
    }),
    addNewCourse: build.mutation({
      query: (course) => {
        const formData = new FormData();
        for (const courseKey in course) {
          formData.append(courseKey, course[courseKey]);
        }
        return {
          url: "/lms/course/create",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: COURSES_TAG, id: "LIST" }],
    }),
    updateCourse: build.mutation({
      query: (course) => {
        const formData = new FormData();
        for (const courseKey in course.item) {
          formData.append(courseKey, course.item[courseKey]);
        }
        return {
          url: `/lms/course/${course.id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: COURSES_TAG, id: arg.id },
      ],
    }),
    deleteCourse: build.mutation({
      query: (id) => ({
        url: `/lms/course/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: COURSES_TAG, id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetCoursesQuery,
  useAddNewCourseMutation,
  useDeleteCourseMutation,
  useUpdateCourseMutation,
} = coursesApiSlice;

export const selectCoursesResult =
  coursesApiSlice?.endpoints.getCourses.select();

const selectCoursesData = createSelector(
  selectCoursesResult,
  (coursesResult) => coursesResult?.data
);
export const { selectAll: selectAllCourses } = coursesAdapter.getSelectors(
  (state) => selectCoursesData(state) ?? initialState
);
