import styled from 'styled-components';

export const Wrapper = styled.section``;

export const Label = styled.label`
  font-weight: bold;
  display: block;
  text-transform: capitalize;
`;
export const ContainerWrapper = styled.div`
  width: 90%;
  margin: 20px auto 0;
`;
export const Input = styled.input`
  outline: none;
  width: 100%;
  padding: 8px 16px;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #707070;
`;

export const TArea = styled.textarea`
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 10px;
  outline: none;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-bottom: 20px;
`;
