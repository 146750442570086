import styled from "styled-components";

export const LoanWrapper = styled.section`
  .card-header {
    border-bottom: 1px solid var(--border-color);
  }

  .card-body {
    background-color: #fff8eb;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ContentCard = styled.article`
  display: flex;
  justify-content: center;
  flex-flow: column;
  background-color: white;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 16px;
  //width: max-content;
  row-gap: 16px;
  max-width: 220px;
  text-align: left;
  flex: 1;
`;

export const ContentHeader = styled.h4`
  margin: 0;
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;

  span {
    font-weight: normal;
    font-size: 14px;
    margin-left: 2px;
    text-transform: uppercase;
    color: #707070;
  }
`;

export const ContentText = styled.p`
  margin: 0;
  font-size: 16px;
  color: #707070;
  font-weight: 600;
`;
