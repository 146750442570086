import React, { useState } from "react";
import { Spinner, Tab, Toast } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AuthRight from "./AuthRight";
import Form from "react-bootstrap/Form";
import { schema } from "./signin-schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoginMutation } from "../../Redux/features/auth/authApiSlice";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../../Redux/features/auth/authSlice";

const Signin = () => {
  const [login, { isLoading }] = useLoginMutation();
  const [showToast, setShowToast] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const navigate = useNavigate();

  const onSubmitHandler = async (data) => {
    try {
      const token = await login({ ...data }).unwrap();
      dispatch(setAccessToken({ ...token }));
      toast("Welcome");
      navigate(`/`, { replace: true });
    } catch (err) {
      if (err.data.message.includes("incorrect")) {
        setErrMsg("Invalid Email or Password");
        setShowToast(true);
      } else {
        setErrMsg("Something went wrong!");
        setShowToast(true);
      }
    }
  };

  const buttonContent = isLoading ? (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
      className="me-1"
    />
  ) : (
    "login"
  );

  return (
    <>
      <div className="body d-flex p-0 p-xl-5">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-6 d-flex justify-content-center align-items-center auth-h100">
              <div className="d-flex flex-column">
                <h1>Account Login</h1>
                <span className="text-muted">
                  Welcome back! Log In with your Email or QR code
                </span>
                <Tab.Container defaultActiveKey="first">
                  <Tab.Content className="tab-content mt-4 mb-3">
                    <Tab.Pane
                      className="tab-pane fade "
                      id="Email"
                      eventKey="first">
                      <div className="card">
                        <div className="card-body p-4">
                          <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <div className="mb-3">
                              <Form.Label className="fs-6" htmlFor="inputEmail">
                                Email address
                              </Form.Label>
                              <Form.Control
                                type="email"
                                id="inputEmail"
                                aria-describedby="emailHelpBlock"
                                {...register("email")}
                              />
                              {errors.email?.message && (
                                <Form.Text
                                  id="emailHelpBlock"
                                  muted
                                  className={"text-danger"}>
                                  Enter a valid email address
                                </Form.Text>
                              )}
                            </div>
                            <div className="mb-3">
                              <Form.Label
                                className="fs-6"
                                htmlFor="inputPassword">
                                Password
                              </Form.Label>
                              <Form.Control
                                {...register("password")}
                                type="password"
                                aria-describedby="passwordHelpBlock"
                                id={"inputPassword"}
                              />
                              {errors.password?.message && (
                                <Form.Text
                                  id="passwordHelpBlock"
                                  muted
                                  className={"text-danger"}>
                                  {errors.password?.message}
                                </Form.Text>
                              )}
                            </div>
                            <button
                              disabled={!isDirty || !isValid || isLoading}
                              type="submit"
                              className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">
                              {buttonContent}
                            </button>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
                <Link
                  to={process.env.PUBLIC_URL + "/forgot-password"}
                  title="#"
                  className="text-primary text-decoration-underline">
                  Forgot password?
                </Link>
                <Link
                  to={process.env.PUBLIC_URL + "/sign-up"}
                  title="#"
                  className="text-primary text-decoration-underline">
                  Register now
                </Link>
              </div>
            </div>
            <AuthRight />
          </div>
        </div>
      </div>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={true}
        style={{ position: "absolute", top: "55px", right: "20px" }}
        className="d-flex align-items-center text-white bg-danger border-0 fade show">
        <Toast.Body>{errMsg}</Toast.Body>
        <button
          type="button"
          className="btn-close btn-close-white ms-auto me-2"
          data-dismiss="toast"
          aria-label="Close"
        />
      </Toast>
    </>
  );
};

export default Signin;
