import styled from "styled-components";

export const CurrentWrapper = styled.section`
  border-radius: 10px;
  border: 1px solid var(--border-color);
  padding: 16px;
  display: grid;
  row-gap: 10px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 158px 1fr;
  grid-gap: 10px;
  align-items: center;
`;

export const Label = styled.h6`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const Text = styled.p`
  padding: 8px 16px;
  color: #707070;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  text-transform: uppercase;
  margin: 0;
  background-color: #fff8eb;
`;
