import React from "react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
};

function CreateSurvey() {
  const creator = new SurveyCreator(creatorOptions);
  creator.saveSurveyFunc = (saveNo, callback) => {
    window.localStorage.setItem("survey-json", creator.text);
    callback(saveNo, true);
  };

  return <SurveyCreatorComponent creator={creator} />;
}

export default CreateSurvey;
