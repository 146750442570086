import React from "react";
import {
  Attachments,
  HRule,
  SubHeader,
  SubjectContainer,
  Text,
  TextHeader,
} from "./styles";

function TicketSubject({ ticket }) {
  return (
    <SubjectContainer>
      <div className={"d-flex align-items-baseline"}>
        <TextHeader>Subject:</TextHeader>
        <SubHeader>{ticket.title}</SubHeader>
      </div>
      <Text>{ticket.content}</Text>
      <HRule />
      <Attachments>
        Attachments
        {ticket.attachments && ticket.attachments.map((file) => file.name)}
      </Attachments>
    </SubjectContainer>
  );
}

export default TicketSubject;
