export const ProfileData = [

    {
        icon: 'icofont-ui-touch-phone',
        detail: '202-555-0174 '
    },
    {
        icon: 'icofont-email',
        detail: 'adrianallan@gmail.com'
    },
    {
        icon: 'icofont-birthday-cake',
        detail: '19/03/1980'
    },
    {
        icon: 'icofont-address-book',
        detail: '2734  West Fork Street,EASTON 02334.'
    }
]