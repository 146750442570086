export const businessColumns = () => {
  return [
    {
      name: 'Name',
      selector: row => row.name,
      cell: row => row.name,
    },
    {
      name: 'Email',
      selector: row => row.email,
      cell: row => row.email,
    },
    {
      name: 'Credit Score',
      selector: row => row.credit,
      sortable: true,
      center: true,
    },
    {
      name: 'Climate Score',
      selector: row => row.climate,
      sortable: true,
      center: true,
    },
    {
      name: 'Carbon Saved(in tons)',
      selector: row => row.carbon.toLocaleString(),
      sortable: true,
      center: true,
    },
  ];
};
