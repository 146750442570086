import React, { useEffect, useState } from "react";
import { HistoryWrapper, TDStatus, THead } from "./styles";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import { defaultData } from "../../../utils/data";
import ReactPaginate from "react-paginate";

function LoanHistory() {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  let itemsPerPage = 5;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(defaultData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(defaultData.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % defaultData.length;
    setItemOffset(newOffset);
  };

  const colors = ["#2445D4", "#707070", "#FFAB00", "#018158", "#E8543B"];
  const statuses = ["Open", "Pending", "Fi-Fi", "Approved", "Rejected"];
  const toggleOptions = ["All", ...statuses];
  const tableHeaders = [
    "Loan Id",
    "Amount (ksh)",
    "Term",
    "Interest",
    "Status",
    "Repayment Period",
    "Requested",
  ];
  return (
    <HistoryWrapper>
      <div className="text-end w-auto m-3">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Filter Loans
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {toggleOptions.map((status) => (
              <Dropdown.Item href="#/action-1">{status}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Table className={"text-center"}>
        <thead>
          <tr>
            {tableHeaders.map((header) => (
              <THead key={header}>{header}</THead>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems !== null &&
            currentItems.map((loan) => {
              const color = colors[loan.status - 1];
              const status = statuses[loan.status - 1];
              return (
                <tr key={loan.id}>
                  <td className={"align-middle"}>{loan.id}</td>
                  <td className={"align-middle"}>{loan.amount}</td>
                  <td className={"align-middle"}>{loan.term}</td>
                  <td className={"align-middle"}>{loan.interest}</td>
                  <TDStatus>
                    <div style={{ borderColor: `${color}`, color: `${color}` }}>
                      {status}
                    </div>
                  </TDStatus>
                  <td className={"align-middle"}>{loan.period}</td>
                  <td className={"align-middle"}>{loan.requested}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<<"
        renderOnZeroPageCount={null}
        containerClassName={"paginate"}
        pageClassName={"page"}
        activeClassName={"page_active"}
        previousClassName={"page"}
        nextClassName={"page"}
      />
    </HistoryWrapper>
  );
}

export default LoanHistory;
