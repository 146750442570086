import React from "react";
import CurrentLoan from "../CurrentLoan/CurrentLoan";
import { Action, TextArea } from "./styles";
import { BsArrowRepeat, BsCheckLg, BsXLg } from "react-icons/bs";
import Button from "react-bootstrap/Button";

function LoanApproval() {
  return (
    <div className={"d-flex"}>
      <CurrentLoan />
      <div className={"d-flex flex-column justify-content-between flex-grow-1"}>
        <div className="d-flex justify-content-between mt-3">
          <Action>
            <BsCheckLg />
            <h6>Approve</h6>
          </Action>
          <Action>
            <BsArrowRepeat />
            <h6>Follow Up</h6>
          </Action>
          <Action>
            <BsXLg />
            <h6>Reject</h6>
          </Action>
        </div>
        <TextArea placeholder={"Reasons for follow up"} />
        <div className="text-end">
          <Button
            style={{ backgroundColor: "#018158", border: "none" }}
            className={"text-uppercase text-light p-3"}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LoanApproval;
