export const defaultData = [
    {
        id: '001',
        status: 1,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
    },
    {
        id: '002',
        status: 2,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
    },
    {
        id: '003',
        status: 3,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
    },
    {
        id: '004',
        status: 4,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
    },
    {
        id: '005',
        status: 5,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
    },
    {
        id: '006',
        status: 1,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
    },
    {
        id: '007',
        status: 2,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
    },
    {
        id: '008',
        status: 3,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
    },
    {
        id: '009',
        status: 4,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
    },
    {
        id: '010',
        status: 5,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
    },
];

export const defaultTickets = [
    {
        id: '001',
        status: 1,
        priority: 'High',
        category: 'Tech',
        assignee: 'Elijah',
    },
    {
        id: '002',
        status: 2,
        priority: 'Medium',
        category: 'Finance',
        assignee: 'Sylvia',
    },
    {
        id: '003',
        status: 3,
        priority: 'Low',
        category: 'Marketing',
        assignee: 'Ephantus',
    },
    {
        id: '004',
        status: 1,
        priority: 'High',
        category: 'Tech',
        assignee: 'MR. G',
    },
    {
        id: '005',
        status: 2,
        priority: 'Low',
        category: 'Unknown',
        assignee: 'Not Assigned',
    },
];

export const defaultLOanApplications = [
    {
        id: '001',
        status: 19,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
        business: 'Lawaju Enterprise',
        product: "Electric bike"
    },
    {
        id: '002',
        status: 19,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
        business: 'EcoMakaa Enterprise',
        product: "Electric tricycle"
    },
    {
        id: '003',
        status: 19,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
        business: 'Mana Limited',
        product: "Solar Water Pump"
    },
    {
        id: '004',
        status: 4,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
        business: 'Lawaju Enterprise',
        date: "21-08-2021",
        product: "Solar Panel"
    },
    {
        id: '005',
        status: 5,
        period: '6years',
        requested: 'Jan 28th',
        interest: 10,
        term: '24 Months',
        amount: 10000,
        business: 'Lawaju Enterprise',
        date: "16-08-2021",
        product: "Electric scooter"
    },
];

export const defaultEntrepreneurs = [
    {
        id: 1,
        name: 'Solar Solutions Inc.',
        email: 'solarsolutions@gmail.com',
        credit: 790,
        climate: 300,
        carbon: 85613,
    },
    {
        id: 2,
        name: 'Green Energy Group',
        email: 'greenenergygroup@yahoo.com',
        credit: 760,
        climate: 450,
        carbon: 68224,
    },
    {
        id: 3,
        name: 'Eco-Friendly Enterprises',
        email: 'ecofriendlyenterprises@hotmail.com',
        credit: 710,
        climate: 100,
        carbon: 27435,
    },
    {
        id: 4,
        name: 'Sustainable Energy Co.',
        email: 'sustainableenergyco@outlook.com',
        credit: 810,
        climate: 750,
        carbon: 90628,
    },
    {
        id: 5,
        name: 'Clean Energy Solutions',
        email: 'cleanenergysolutions@gmail.com',
        credit: 780,
        climate: 900,
        carbon: 72502,
    },
    {
        id: 6,
        name: 'Renewable Energy Corp.',
        email: 'renewableenergycorp@icloud.com',
        credit: 750,
        climate: 600,
        carbon: 17557,
    },
    {
        id: 7,
        name: 'Wind Power Systems',
        email: 'windpowersystems@aol.com',
        credit: 800,
        climate: 200,
        carbon: 83469,
    },
    {
        id: 8,
        name: 'GreenTech Industries',
        email: 'greentechindustries@protonmail.com',
        credit: 730,
        climate: 350,
        carbon: 63003,
    },
    {
        id: 9,
        name: 'Sunrise Energy Solutions',
        email: 'sunriseenergysolutions@msn.com',
        credit: 770,
        climate: 500,
        carbon: 90112,
    },
    {
        id: 10,
        name: 'EcoPower Group',
        email: 'ecopowergroup@gmail.com',
        credit: 740,
        climate: 800,
        carbon: 53691,
    },
];

export const defaultLoanProducts = [
    {
        id: 1,
        name: 'Electric bike',
        type: 'E-Mobility',
    },
    {
        id: 2,
        name: 'Electric TukTUk',
        type: 'E-Mobility',
    },
    {
        id: 3,
        name: 'Electric scooter',
        type: 'E-Mobility',
    },
    {
        id: 4,
        name: 'Electric car',
        type: 'E-Mobility',
    },
    {
        id: 5,
        name: 'Electric truck',
        type: 'E-Mobility',
    },
    {
        id: 6,
        name: 'Solar Panels',
        type: 'Solar Equipment',
    },
    {
        id: 7,
        name: 'Solar Water Heater',
        type: 'Solar Equipment',
    },
    {
        id: 8,
        name: 'Solar Water Pump',
        type: 'Solar Equipment',
    },
    {
        id: 9,
        name: 'Solar Fridge',
        type: 'Solar Equipment',
    },
];
