import React from "react";
import Card from "react-bootstrap/Card";
import { ContentCard, ContentHeader, ContentText, LoanWrapper } from "./styles";

function LoanSummary() {
  return (
    <LoanWrapper>
      <Card>
        <Card.Header className={"p-3"} as={"h5"}>
          Loan Summary
        </Card.Header>
        <Card.Body>
          <ContentCard>
            <ContentHeader>
              500,000 <span>ksh</span>
            </ContentHeader>
            <ContentText>Amount Funded</ContentText>
          </ContentCard>
          <ContentCard>
            <ContentHeader>
              200,000 <span>ksh</span>
            </ContentHeader>
            <ContentText>Amount Left To Pay</ContentText>
          </ContentCard>
          <ContentCard>
            <ContentHeader>2 of 6</ContentHeader>
            <ContentText>Completed Payments</ContentText>
          </ContentCard>
          <ContentCard>
            <ContentHeader>5</ContentHeader>
            <ContentText>Missed Payments</ContentText>
          </ContentCard>
          <ContentCard>
            <ContentHeader>20</ContentHeader>
            <ContentText>Loans Acquired</ContentText>
          </ContentCard>
        </Card.Body>
      </Card>
    </LoanWrapper>
  );
}

export default LoanSummary;
