import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { CATEGORIES_TAG } from "../../../constants";

const categoriesAdapter = createEntityAdapter();

const initialState = categoriesAdapter.getInitialState();

const categoriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query({
      query: () => "/category/list",
      transformResponse: (rawResult) => {
        return categoriesAdapter.setAll(
          initialState,
          rawResult?.response?.data
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: CATEGORIES_TAG, id })),
              { type: CATEGORIES_TAG, id: "LIST" },
            ]
          : [{ type: CATEGORIES_TAG, id: "LIST" }],
    }),
    getCategoryById: build.query({
      query: (id) => `/category/${id}`,
      transformResponse: (rawResult) => rawResult?.response,
    }),
    addNewCategory: build.mutation({
      query: (category) => {
        const formData = new FormData();
        for (const categoryKey in category) {
          formData.append(categoryKey, category[categoryKey]);
        }
        return {
          url: "/lms/category/create",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: CATEGORIES_TAG, id: "LIST" }],
    }),
    deleteCategory: build.mutation({
      query: (id) => {
        return {
          url: `/category/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: CATEGORIES_TAG, id: arg.id },
      ],
    }),
    updateCategory: build.mutation({
      query: (category) => {
        const formData = new FormData();
        for (const categoryKey in category.item) {
          formData.append(categoryKey, category.item[categoryKey]);
        }
        return {
          url: `/lms/category/${category.id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: CATEGORIES_TAG, id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useAddNewCategoryMutation,
  useGetCategoryByIdQuery,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} = categoriesApiSlice;

export const selectCategoriesResult =
  categoriesApiSlice?.endpoints.getCategories.select();

const selectCategoriesData = createSelector(
  selectCategoriesResult,
  (categoriesResult) => categoriesResult?.data
);

export const { selectAll: selectAllCategories, selectIds: selectCategoryIds } =
  categoriesAdapter.getSelectors(
    (state) => selectCategoriesData(state) ?? initialState
  );
