import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { BUSINESSES_TAG } from "../../../constants";

const businessAdapter = createEntityAdapter();
const initialState = businessAdapter.getInitialState();

const businessSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getBusinesses: build.query({
      query: () => "/business/list",
      transformResponse: (rawResult) =>
        businessAdapter.setAll(initialState, rawResult?.response),
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: BUSINESSES_TAG, id })),
              { type: BUSINESSES_TAG, id: "LIST" },
            ]
          : [{ type: BUSINESSES_TAG, id: "LIST" }],
    }),
    getBusinessById: build.query({
      query: (id) => `/business/${id}`,
      transformResponse: (rawResult) => rawResult?.response,
    }),
  }),
});

export const { useGetBusinessesQuery, useGetBusinessByIdQuery } = businessSlice;
export const selectBusinessResult =
  businessSlice?.endpoints?.getBusinesses.select();

const selectBusinessData = createSelector(
  selectBusinessResult,
  (businessResult) => businessResult?.data
);

export const { selectAll: selectAllBusinesses } = businessAdapter.getSelectors(
  (state) => selectBusinessData(state) ?? initialState
);
