import React, { useEffect, useState } from "react";
import {
  HistoryWrapper,
  TDStatus,
  THead,
} from "../LoanApplications/LoanHistory/styles";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { useGetAllTicketsQuery } from "../../Redux/features/tickets/tickets";
import { formatDistanceToNow, parseISO } from "date-fns";

const TicketList = () => {
  const navigate = useNavigate();
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  let itemsPerPage = 5;

  const {
    isLoading,
    isError,
    isSuccess,
    error,
    data: tickets,
  } = useGetAllTicketsQuery("getAllTickets");

  let allTickets = [];
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(allTickets.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(allTickets.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, tickets]);

  if (isLoading) {
    return <p>Loading.....</p>;
  } else if (isSuccess) {
    allTickets = tickets.ids.map((id) => {
      return tickets.entities[id];
    });
  } else if (isError) {
    console.log(error);
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allTickets.length;
    setItemOffset(newOffset);
  };

  const onTicketClick = (id) => {
    navigate(`${id}`);
  };

  const colors = ["#2445D4", "#707070", "#018158"];
  const statuses = ["Open", "Pending", "Closed"];
  const toggleOptions = ["All", ...statuses];
  const tableHeaders = [
    "Ticket Id",
    "Priority",
    "Created",
    "Assignee",
    "Status",
  ];
  return (
    <HistoryWrapper>
      <div className="text-end w-auto m-3">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Filter Tickets
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {toggleOptions.map((status) => (
              <Dropdown.Item key={status} href="#/action-1">
                {status}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Table className={"text-center"}>
        <thead>
          <tr>
            {tableHeaders.map((header) => (
              <THead key={header}>{header}</THead>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems !== null &&
            currentItems.map((ticket) => {
              let color;
              let status;
              let priority;
              if (ticket.status_id === null) {
                color = colors[1];
                status = statuses[1];
              }

              switch (ticket.priority_id) {
                case 1:
                  priority = "Medium";
                  break;
                case 2:
                  priority = "Low";
                  break;
                default:
                  priority = "High";
              }

              return (
                <tr
                  onClick={() => onTicketClick(ticket.id)}
                  style={{ cursor: "pointer" }}
                  key={ticket.id}>
                  <td className={"align-middle"}>{ticket.id}</td>
                  <td className={"align-middle"}>{priority}</td>
                  <td className={"align-middle"}>
                    {formatDistanceToNow(parseISO(ticket.created_at))} ago
                  </td>
                  <td className={"align-middle"}>
                    {ticket.assigned_to_user_id ?? "Unassigned"}
                  </td>
                  <TDStatus>
                    <div style={{ borderColor: `${color}`, color: `${color}` }}>
                      {status}
                    </div>
                  </TDStatus>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<<"
        renderOnZeroPageCount={null}
        containerClassName={"paginate"}
        pageClassName={"page"}
        activeClassName={"page_active"}
        previousClassName={"page"}
        nextClassName={"page"}
      />
    </HistoryWrapper>
  );
};

export default TicketList;
