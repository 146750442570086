import { apiSlice } from "../../app/api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query({
      query: () => "/user/profile",
      transformResponse(response) {
        return response?.response;
      },
    }),
    updateProfile: build.mutation({
      query: (payload) => {
        const data = new FormData();
        for (const itemKey in payload.item) {
          data.append(itemKey, payload.item[itemKey]);
        }
        return {
          url: `user/update/${payload.id}`,
          method: "POST",
          body: data,
        };
      },
    }),
    assignRole: build.mutation({
      query: (payload) => {
        const data = new FormData();
        data.append("attach_role", payload.key);
        return {
          url: `/assign/user/role/${payload.id}`,
          method: "POST",
          body: data,
        };
      },
    }),
    logOut: build.query({
      query: () => `/user/logout`,
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateProfileMutation,
  useAssignRoleMutation,
  useLogOutQuery,
} = userApiSlice;
