import { apiSlice } from "../../app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { FAQS_TAG } from "../../../constants";

const faqsAdapter = createEntityAdapter();
const initialState = faqsAdapter.getInitialState();

const faqsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFaqs: build.query({
      query: () => "/faqs/list",
      transformResponse: (rawResult) =>
        faqsAdapter.setAll(initialState, rawResult?.response?.data),
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: FAQS_TAG, id }))]
          : [{ type: FAQS_TAG, id: "LIST" }],
    }),
    addFaq: build.mutation({
      query: (faq) => {
        const faqData = new FormData();
        for (const faqKey in faq) {
          faqData.append(faqKey, faq[faqKey]);
        }
        return {
          url: "/faqs/create",
          method: "POST",
          body: faqData,
        };
      },
      invalidatesTags: [{ type: FAQS_TAG, id: "LIST" }],
    }),
  }),
});

export const { useGetFaqsQuery, useAddFaqMutation } = faqsApiSlice;

const selectFaqsResult = faqsApiSlice?.endpoints.getFaqs.select();
const selectFaqsData = createSelector(
  selectFaqsResult,
  (faqsResult) => faqsResult?.data
);

export const { selectAll: selectAllFaqs } = faqsAdapter.getSelectors(
  (state) => selectFaqsData(state) ?? initialState
);
