import React from "react";
import {
  Avatar,
  CommentsWrapper,
  Count,
  Duration,
  Header,
  HRule,
  HRuleMain,
  SingleComment,
  Text,
  Username,
} from "./styles";

function Comments() {
  // noinspection JSValidateTypes
  return (
    <CommentsWrapper>
      <div className="header">
        <Header>Comments</Header>
        <Count>10</Count>
      </div>
      <HRuleMain />
      {[1, 2, 3, 4].map((el) => (
        <div key={el}>
          <SingleComment>
            <Avatar
              src={
                "https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/avatar.jpg?alt=media&token=0ae57f44-06fd-401d-b1e3-9f182515bcc6"
              }
              alt={"comment author"}
            />
            <div>
              <div className="head">
                <Username>Jane Doe</Username>
                <Duration>3h ago</Duration>
              </div>
              <Text>
                Lorem ipsum dolor sit amet. Qui similique tenetur eum porro
                sapiente et unde aperiam est repellat sint
              </Text>
            </div>
          </SingleComment>
          <HRule />
        </div>
      ))}
    </CommentsWrapper>
  );
}

export default Comments;
