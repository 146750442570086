import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BUSINESSES_TAG,
  CATEGORIES_TAG,
  COURSES_TAG,
  EXPERTS_TAG,
  FAQS_TAG,
  LESSONS_TAG,
  LOAN_PRODUCTS,
  LOAN_TRANSACTIONS,
  LOANS_TAG,
  MARKET_TAG,
  POSTS,
  ROLES,
  SURVEY_FORM,
  SURVEY_RESULTS,
  TICKETS_TAG,
} from '../../../constants';
import { logOut, setAccessToken } from '../../features/auth/authSlice';

const baseUrl = process.env.REACT_APP_BASE_URL;

console.log("react base: ", baseUrl);

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (
    result?.error?.status === 'PARSING_ERROR' &&
    result?.error?.originalStatus === 403
  ) {
    console.log('Sending refresh token');
    const refreshResult = await baseQuery('/refresh', api, extraOptions);
    console.log('RefreshResult:', refreshResult);
    if (refreshResult?.data) {
      api.dispatch(setAccessToken(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};
export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
  tagTypes: [
    COURSES_TAG,
    LESSONS_TAG,
    CATEGORIES_TAG,
    BUSINESSES_TAG,
    TICKETS_TAG,
    LOANS_TAG,
    LOAN_PRODUCTS,
    LOAN_TRANSACTIONS,
    SURVEY_FORM,
    SURVEY_RESULTS,
    POSTS,
    ROLES,
    FAQS_TAG,
    EXPERTS_TAG,
    MARKET_TAG,
  ],
});
