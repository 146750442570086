import styled from 'styled-components';
import { GrDocumentText } from 'react-icons/gr';

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 20px;
  align-items: center;
  margin: 0 30px 20px;

  h6 {
    margin: 0;
    padding: 0;
    color: #707070;
    text-transform: uppercase;
  }

  &.checklist {
    margin-top: 30px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const Num = styled.div`
  border-radius: 50%;
  background-color: #146c43;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  column-gap: 20px;
  border-radius: 5px;
  border: 1px solid #707070;
`;

export const Doc = styled(GrDocumentText)`
  width: 30px;
  height: 50px;
`;
export const Details = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;

  .info {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: #707070;
    font-size: 14px;
    max-width: 80%;
    column-gap: 20px;
  }
`;

export const Button = styled.button`
  border-radius: 5px;
  border: 1px solid #146c43;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fff;

  &.download {
    background-color: #146c43;
    color: #fff;
  }
`;

export const ChecklistWrapper = styled.div`
  width: 90%;
  border-radius: 5px;
  border: 1px solid #707070;
  padding: 16px;
  margin: 10px auto 0;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const CHeader = styled.div`
  display: flex;
  border-bottom: 2px solid #146c43;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;

  p:last-child {
    position: absolute;
    right: 0;
  }
`;

export const DocWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

export const DocContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #707070;
  align-items: center;
`;

export const DocDetail = styled.p`
  flex: 1;
  text-align: center;
`;

export const Chip = styled.p`
  background-color: ${props => (props?.isChecked ? `#146c43` : `#707070`)};
  padding: 3px 7px !important;
  font-size: 13px;
  color: white;
  border-radius: 15px;
`;

export const SaveBtn = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 30px;

  button {
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #146c43;
    padding: 5px 8px;
    position: relative;
    background-color: #fff;
  }
`;
