import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { faqColumns } from "./faqsColumns";
import EditFaq from "./EditFaq";

function FaqList({ isLoading, table_row }) {
  const [isEditModal, setIsEditModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const [deleteRow, setDeleteRow] = useState({});
  const handleShow = (row) => {
    setSelectedPost(row);
  };

  const onDeleteFaq = async (row) => {
    setDeleteRow(row);
    try {
    } catch (e) {
      setDeleteRow({});
      console.log(e);
    }
  };

  const buttonContent = (row) => {
    return "Delete";
  };

  const editButton = {
    name: "Edit",
    selector: () => {},
    cell: (row) => (
      <button className="btn btn-light-success" onClick={() => handleShow(row)}>
        Edit Faq
      </button>
    ),
  };

  const deleteButton = {
    name: "Delete",
    selector: () => {},
    cell: (row) => (
      <button className="btn btn-light-danger" onClick={() => onDeleteFaq(row)}>
        {buttonContent(row)}
      </button>
    ),
  };

  return (
    <>
      <div className="col-xl-12">
        <div className="card no-bg">
          <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0 align-items-center">
            <h6 className="mb-0 fw-bold ">List of faqs</h6>
          </div>
          <div className="card-body">
            <div
              id="myProjectTable_wrapper"
              className="dataTables_wrapper dt-bootstrap5 no-footer">
              <div className="row">
                <div
                  className="col-sm-12 col-md-6 mb-2 "
                  style={{ display: "flex", alignItems: "center" }}>
                  <div id="myDataTable_filter" className="dataTables_filter">
                    <label className="d-flex align-items-center">
                      Search:
                      <div className="mx-1">
                        <input type="search" className="form-control" />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <DataTable
                    columns={[...faqColumns(), editButton, deleteButton]}
                    data={table_row}
                    pagination
                    selectableRows={false}
                    highlightOnHover={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditFaq
        show={isEditModal}
        onClose={setIsEditModal}
        post={selectedPost}
      />
    </>
  );
}

export default FaqList;
