import ProfileImage1 from '../../../assets/images/xs/avatar1.svg';
import ProfileImage2 from '../../../assets/images/xs/avatar2.svg';
import ProfileImage3 from '../../../assets/images/xs/avatar3.svg';
import ProfileImage4 from '../../../assets/images/xs/avatar4.svg';
import ProfileImage5 from '../../../assets/images/xs/avatar5.svg';
import ProfileImage6 from '../../../assets/images/xs/avatar6.svg';
import ProfileImage7 from '../../../assets/images/xs/avatar7.svg';
import ProfileImage8 from '../../../assets/images/xs/avatar8.svg';

export const WinningStreakData = {

    columns: [
        {
            name: " NO",
            selector: (row) => row.ranking,
            sortable: true,
            maxWidth: '50px',
            // cell: (row) => <a href="#!" className="fw-bold text-secondary">{row.id}</a>
        },

        {
            name: "NAME",
            selector: (row) => row.name,
            cell: row => <> <img src={row.image} alt="" className="img-fluid avatar mx-1" /><h6 className='text-bold'>{row.name}</h6></>,
            sortable: true,
        },
        {
            name: "MAX LOAN",
            selector: (row) => row.winningstreak,
            sortable: true
        },
        {
            name: "SMEs LENDED",
            selector: (row) => row.point,
            sortable: true
        }

    ],
    rows: [
        {
            ranking:'1',
            image:ProfileImage1,
            name:'BirdPK',
            winningstreak:'13000000',
            point:'5'
        },
        {
            ranking:'2',
            image:ProfileImage2,
            name:'Xor',
            winningstreak:'10000000',
            point:'4'
        },
        {
            ranking:'3',
            image:ProfileImage3,
            name:'KCB',
            winningstreak:'7000000',
            point:'	18'
        },
        {
            ranking:'4',
            image:ProfileImage4,
            name:'GIZ',
            winningstreak:'4000000',
            point:'	7'
        },
        {
            ranking:'5',
            image:ProfileImage5,
            name:'Absa',
            winningstreak:'3000000',
            point:'12'
        },
    ]
}