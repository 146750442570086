import React, { useState } from "react";

function AddYourCoin(props) {
  const [firstStep, setFirstStep] = useState(false);
  const closeForm = () => {
    props.onCreateCancel(false);
  };

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header">
          <h5 className="mb-0">Create course</h5>
        </div>
        <div className="card-body">
          <div className="wizard-main" id="w-horizontal">
            <div className="step-app">
              <div className="step-content">
                <div
                  className={`step-tab-panel active ${
                    firstStep ? "d-none" : ""
                  }`}
                  data-step="step1">
                  <form>
                    <div className="row">
                      <div className="col-md-4 col-12 mb-3">
                        <label className="col-form-label">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Accounting fundamentals"
                        />
                      </div>
                      <div className="col-md-4 col-12 mb-3">
                        <label className="col-form-label">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Something..."
                        />
                      </div>
                      <div className="col-md-4 col-12 mb-3">
                        <label className="col-form-label">Category</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Finance"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="step-footer d-flex">
                <button
                  className="btn btn-danger"
                  style={{ color: "white" }}
                  onClick={closeForm}>
                  Close
                </button>
                <button
                  data-step-action="next"
                  className="btn btn-primary step-btn">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddYourCoin;
