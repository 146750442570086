export const expertsColumns = () => {
  return [
    {
      name: 'Name',
      selector: row => row?.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row?.email,
      sortable: true,
    },
    {
      name: 'Title',
      selector: row => row?.title,
      sortable: true,
    },
    {
      name: 'Company',
      selector: row => row?.company,
      sortable: true,
    },
  ];
};
