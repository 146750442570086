import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { BusinessWrapper, Header } from './styles';
import LoanDetails from '../LoanDetails/LoanDetails';
import LoanApproval from '../LoanApproval/LoanApproval';
import CompanyDocs from '../CompanyDocs/CompanyDocs';
import CompanyProfile from '../CompanyProfile/CompanyProfile';

const BusinessSummary = () => {
  return (
    <BusinessWrapper>
      <Header>Business Summary</Header>
      <Tabs
        defaultActiveKey='loan-details'
        id='justify-tab-example'
        className='mb-3'
        justify>
        <Tab eventKey='loan-details' title='Loan Details'>
          <LoanDetails />
        </Tab>
        <Tab eventKey='company-profile' title='Company Profile'>
          <CompanyProfile />
        </Tab>
        <Tab eventKey='company-documents' title='Company Documents'>
          <CompanyDocs />
        </Tab>
        <Tab eventKey='loan-approval' title='Loan Approval'>
          <LoanApproval />
        </Tab>
      </Tabs>
    </BusinessWrapper>
  );
};

export default BusinessSummary;
