import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, "App name is too short")
    .required("App name is required"),
  description: yup
    .string()
    .min(6, "App description is too short")
    .required("App description is required"),
  type: yup.string().required("App type is required"),
  base_url: yup.string().required("App url is required"),
  category_id: yup.string().required("Please choose category"),
});
