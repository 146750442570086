import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { LOAN_PRODUCTS } from "../../../constants";

const loanProductsAdapter = createEntityAdapter();

const initialState = loanProductsAdapter.getInitialState();

export const loanProductsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAllLoanProducts: build.query({
      query: () => `/loan/product/list`,
      transformResponse: (rawResult) =>
        loanProductsAdapter.setAll(initialState, rawResult?.response?.data),
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: LOAN_PRODUCTS, id })),
              { type: LOAN_PRODUCTS, id: "LIST" },
            ]
          : [{ type: LOAN_PRODUCTS, id: "LIST" }],
    }),
    getLoanProductById: build.query({
      query: (id) => `/loan/product/${id}`,
      transformResponse: (rawResult) => rawResult?.response,
    }),
    deleteLoanProduct: build.mutation({
      query: (id) => {
        return {
          url: `/loan/product/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: LOAN_PRODUCTS, id: arg.id },
      ],
    }),
    createLoanProduct: build.mutation({
      query: (args) => {
        const data = new FormData();
        for (const argsKey in args) {
          data.append(argsKey, args[argsKey]);
        }
        return {
          url: `/loan/product/create`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: LOAN_PRODUCTS, id: "LIST" }],
    }),
    updateLoanProduct: build.mutation({
      query: (args) => {
        const data = new FormData();
        for (const argsKey in args.item) {
          data.append(argsKey, args.item[argsKey]);
        }
        return {
          url: `/loan/product/${args.id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: LOAN_PRODUCTS, id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetAllLoanProductsQuery,
  useCreateLoanProductMutation,
  useGetLoanProductByIdQuery,
  useUpdateLoanProductMutation,
  useDeleteLoanProductMutation,
} = loanProductsApiSlice;
