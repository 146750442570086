import React from "react";
import DataTable from "react-data-table-component";
import {CustomLoader} from "../LoanApplicationsList/LoanApplicationsList";
import {LoanProductColumns} from "./LoanProductsColumn";
import {defaultLoanProducts} from "../../utils/data";

function LoanProductsList() {
    /*  const {
        data: products,
        isLoading,
        isSuccess,
        isError,
        error,
      } = useGetAllLoanProductsQuery();
      const [isEdit, setIsEdit] = useState(false);
      const [deleteLoanProduct, { isLoading: loading }] =
        useDeleteLoanProductMutation();
      const onDeleteProduct = async (row) => {
        try {
          await deleteLoanProduct(row.id).unwrap();
        } catch (e) {
          console.log(e);
        }
      };

      let table_row = [];
      if (isLoading) {
      } else if (isSuccess) {
        table_row = products.ids.map((id) => products.entities[id]);
      } else if (isError) {
        console.log(error);
      }*/

    const handleEdit = (row) => {
        // setIsEdit(true);
    };

    const buttonContent = "Delete";
    const editButton = {
        name: "ACTION",
        selector: () => {
        },
        sortable: true,
        cell: (row) => (
            <button
                type="submit"
                className="btn btn-light-success"
                onClick={() => handleEdit(row)}>
                Edit Product
            </button>
        ),
    };

    const deleteButton = {
        name: "ACTION",
        selector: () => {
        },
        sortable: true,
        cell: (row) => (
            <button
                type="submit"
                className="btn btn-light-danger"
                onClick={() => {
                }}>
                {buttonContent}
            </button>
        ),
    };

    return (
        <div className="col-xl-12">
            <div className="card no-bg">
                <div
                    className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0 align-items-center">
                    <h6 className="mb-0 fw-bold ">Loan Products</h6>
                </div>
                <div className="card-body">
                    <div
                        id="myProjectTable_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div className="row">
                            <div
                                className="col-sm-12 col-md-6 mb-2 "
                                style={{display: "flex", alignItems: "center"}}>
                                <div id="myDataTable_filter" className="dataTables_filter">
                                    <label className="d-flex align-items-center">
                                        Search:
                                        <div className="mx-1">
                                            <input type="search" className="form-control"/>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <DataTable
                                    columns={[...LoanProductColumns(), editButton, deleteButton]}
                                    data={defaultLoanProducts}
                                    onRowClicked={(row) => handleEdit(row)}
                                    pagination
                                    selectableRows={false}
                                    highlightOnHover={true}
                                    pointerOnHover={true}
                                    progressComponent={<CustomLoader/>}
                                    persistTableHead={true}
                                    noDataComponent={"There are no loan products yet!"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoanProductsList;
