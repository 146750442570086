import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../Component/Comman/PageTitle';
import {
  selectAllExperts,
  useGetExpertsQuery,
} from '../../Redux/features/experts/expertsApiSlice';
import ExpertsList from './ExpertsList';
import CreateExpert from './CreateExpert';

const Experts = () => {
  let table_row = [];
  const { isLoading, isSuccess, isError, error } = useGetExpertsQuery();
  const experts = useSelector(selectAllExperts);

  if (isSuccess) {
    table_row = experts;
    console.log(experts);
  } else if (isError) {
    console.log(error);
  }

  const [displayCreate, setDisplayCreate] = useState(false);

  const createExpert = () => {
    setDisplayCreate(true);
  };

  const closeForm = () => {
    setDisplayCreate(false);
  };

  return (
    <>
      <div className='body-header border-bottom d-flex py-3 mb-3'>
        <PageTitle pagetitle='Experts' />
      </div>
      <div className='container-xxl'>
        {displayCreate ? (
          <div className='row mb-3'>
            <CreateExpert onCreateCancel={closeForm} />
          </div>
        ) : (
          <div className='d-flex'>
            <button className='btn btn-primary mb-2' onClick={createExpert}>
              Add expert
            </button>
          </div>
        )}
        <div className='row'>
          <ExpertsList table_row={table_row} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default Experts;
