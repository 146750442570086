export const appsColumns = () => {
  return [
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Vendor",
      selector: (row) => row?.vendor,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row?.category,
      sortable: true,
    },
    {
      name: "type",
      selector: (row) => row?.type,
      sortable: true,
    },
    /*  {
        name: "App Link",
        selector: (row) => row?.answer,
        sortable: true,
      },*/
  ];
};
