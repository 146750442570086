import React, { useState } from "react";
import AddLoanProduct from "./AddLoanProduct";
import LoanProductsList from "./LoanProductsList";

function LoanProducts() {
  const [displayCreate, setDisplayCreate] = useState(false);

  const addProduct = () => {
    setDisplayCreate(true);
  };

  const close = () => {
    setDisplayCreate(false);
  };

  return (
    <>
      <div className="d-flex">
        {displayCreate ? (
          ""
        ) : (
          <button
            data-step-action="next"
            className="btn btn-primary step-btn"
            onClick={addProduct}>
            Add Loan Product
          </button>
        )}
      </div>
      <div className="row mb-3">
        {displayCreate ? <AddLoanProduct onCreateCancel={close} /> : ""}
      </div>
      <div className="row">
        <LoanProductsList />
      </div>
    </>
  );
}

export default LoanProducts;
