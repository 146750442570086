import React, { useState } from 'react';
import Chart from 'react-apexcharts';

function OtcPortalLimit() {
  const [options, setOptions] = useState({
    chart: {
      height: 380, type: 'donut', labels: []
    }, plotOptions: {
      pie: {
        startAngle: -90, endAngle: 270,
      },
    }, dataLabels: {
      enabled: true, style: {
        colors: ['#ffffff']
      },
      background: {
        enabled: false
      },
      dropShadow: {
        enabled: false
      }
    }, fill: {
      type: 'gradient',
    }, legend: {
      formatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
      },
    }, title: {
      text: '',
    }, responsive: [{
      breakpoint: 480, options: {
        chart: {
          width: 200,
        }, legend: {
          position: 'bottom',
        },
      },
    },],
    labels: ['Electric bike', 'Electric TukTUk',
      'Electric scooter', 'Electric car', 'Electric truck',
      'Solar Panels', 'Solar Water Heater', 'Solar Water Pump', 'Solar Fridge']
  });

  const [series, setSeries] = useState([37, 65, 44, 24, 57, 121, 148, 193, 111]);
  return (<>
    <div className='col-xl-12' style={{position: 'relative'}}>
      <Chart
          options={options}
          series={series}
          type='donut'
          width={380}
      />
    </div>
  </>);
}

export default OtcPortalLimit;
