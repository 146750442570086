import React from "react";
import PageTitle from "../../Component/Comman/PageTitle";
import { Outlet } from "react-router";

function Academy() {
  return (
    <>
      <div className="body-header border-bottom d-flex py-3 mb-3">
        <PageTitle pagetitle="Academy" />
      </div>
      <div className="container-xxl">
        <Outlet />
      </div>
    </>
  );
}

export default Academy;
