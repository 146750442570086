import React from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { LoanApplicationColumns } from './LoanApplicationColumns';
import { Spinner } from 'react-bootstrap';
import { defaultLOanApplications } from '../../utils/data';

export function CustomLoader() {
  return (
    <Spinner
      animation='grow'
      className='me-3'
      style={{ width: '3rem', height: '3rem' }}
    />
  );
}

function LoanApplicationsList(props) {
  // const [displayCreate, setDisplayCreate] = useState(false);
  const navigate = useNavigate();
  /*  const {
    data: loans,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllLoansQuery("getAllLoans");*/

  const openLoan = row => {
    navigate(`${row.id}`);
  };

  /*  if (isLoading) {
    } else if (isSuccess) {
      table_row = loans.ids.map((id) => loans.entities[id]);
    } else if (isError) {
      console.log(error);
    }*/

  return (
    <div className='col-xl-12'>
      <div className='card no-bg'>
        <div className='card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0 align-items-center'>
          <h6 className='mb-0 fw-bold '>Loan Applications</h6>
        </div>
        <div className='card-body'>
          <div
            id='myProjectTable_wrapper'
            className='dataTables_wrapper dt-bootstrap5 no-footer'>
            <div className='row'>
              <div
                className='col-sm-12 col-md-6 mb-2 '
                style={{ display: 'flex', alignItems: 'center' }}>
                <div id='myDataTable_filter' className='dataTables_filter'>
                  <label className='d-flex align-items-center'>
                    Search:
                    <div className='mx-1'>
                      <input type='search' className='form-control' />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <DataTable
                  columns={LoanApplicationColumns()}
                  data={defaultLOanApplications}
                  onRowClicked={row => openLoan(row)}
                  pagination
                  selectableRows={false}
                  highlightOnHover={true}
                  pointerOnHover={true}
                  progressComponent={<CustomLoader />}
                  persistTableHead={true}
                  noDataComponent={'There are no loan applications yet!'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanApplicationsList;
