import React from 'react';
import {
  Container,
  ContainerWrapper,
  Input,
  Label,
  TArea,
  Wrapper,
} from './styles';
import { Header } from '../CompanyDocs/styles';

function CompanyProfile() {
  return (
    <Wrapper>
      <Header>
        <hr />
        <h6>Company Details</h6>
        <hr />
      </Header>
      <ContainerWrapper>
        {/*<Container>*/}
        {/*  <div>*/}
        {/*    <Label>Type of Company</Label>*/}
        {/*    <Input type={'text'} value={'Kelvin Wachiye'} />*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <Label>Business Sector</Label>*/}
        {/*    <Input type={'text'} value={'kelybush@gmail.com'} />*/}
        {/*  </div>*/}
        {/*</Container>*/}
        <Label>Brief company history:</Label>
        <TArea
          rows={15}
          value={
            "Green Energy Inc. was founded in 2005 by a group of environmentally conscious entrepreneurs. The company's mission was to provide clean and renewable energy sources to communities and businesses in order to combat climate change.\n" +
            'In its early years, Green Energy Inc. focused on developing and installing solar panels on residential homes and small businesses. As the demand for renewable energy grew, the company expanded its offerings to include wind and geothermal energy systems.\n' +
            "In 2010, Green Energy Inc. went public and began trading on the stock market. The company's financial success allowed it to invest in research and development, leading to the creation of more advanced and efficient renewable energy technology.\n" +
            'In 2015, Green Energy Inc. formed a partnership with a major utility company to bring solar power to low-income communities. The project was a success and helped to bring renewable energy to underserved areas.\n' +
            'Over the next few years, Green Energy Inc. continued to expand its offerings and services, including the installation of electric vehicle charging stations and the development of energy storage solutions.\n' +
            'In 2020, Green Energy Inc. announced that it had reached its goal of supplying 100% renewable energy to all of its customers. The company continues to innovate and invest in new technology in order to promote a sustainable energy future for all.'
          }
        />
      </ContainerWrapper>
    </Wrapper>
  );
}

export default CompanyProfile;
