import React from "react";
import { Tab, Nav } from "react-bootstrap";
import PageTitle from "../../Component/Comman/PageTitle";
import P2PBuyTabing from "../../Component/P2P/P2PBuyTabing";
import P2PsellTabing from "../../Component/P2P/P2PsellTabing";
import MarketTabing from "../../Component/Market/MarketTabing";
// import P2PTopCard from "../../Component/Admin/P2PTopCard";

function Admin() {
  return (
    <>
      <Tab.Container defaultActiveKey="first">
        <div className="body-header border-bottom d-flex py-3 mb-3">
          <PageTitle pagetitle="Admin" />
        </div>
        <div className="container-xxl">
          <MarketTabing />
        </div>
      </Tab.Container>
    </>
  );
}

export default Admin;
