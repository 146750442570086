import React from 'react';
import PageTitle from '../../Component/Comman/PageTitle';
import BusinessList from './BusinessList';

const EntrepreneursList = () => (
  <>
    <div className='body-header border-bottom d-flex py-3 mb-3'>
      <PageTitle pagetitle='Entrepreneurs' />
    </div>
    <BusinessList />
  </>
);

export default EntrepreneursList;
