export const LoanProductColumns = () => {
  return [
    {
      name: 'ID',
      selector: row => row?.id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row?.name,
      sortable: true,
    },
    {
      name: 'Type',
      selector: row => row?.type,
      sortable: true,
    },
/*    {
      name: 'Vendor',
      selector: row => row?.vendor,
      sortable: true,
    },*/
  ];
};
