import styled from "styled-components";

export const SingleTicketWrapper = styled.main`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 16px;
`;

export const MainContent = styled.section``;
export const CommentsSection = styled.section``;
export const Title = styled.h3`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.58px;
  padding: 4px;
  border-bottom: solid 2px #ffab00;
`;
