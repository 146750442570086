import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { UpcommingListData } from "../Data/IcoData/UpcommingListData";
import { Link } from "react-router-dom";
// import LessonsList from "./LessonsList";

function SurveyList(props) {
    const [table_row, settable_row] = useState([...UpcommingListData.rows]);
    const [iseditmodal, setiseditmodal] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);
    const [displayCreateLesson, setDisplayCreateLesson] = useState(false);

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setiseditmodal(true);
    }

    const closeCreateLessonForm = () => {
        setDisplayCreateLesson(false)
    }

    const createLesson = () => {
        setDisplayCreateLesson(true);
    }

    const columns = () => {
        return [
            {
                name: "ID",
                selector: (row) => row.name,
                cell: row => <><img src={row.image} alt="" className="img-fluid avatar mx-1" /> {row.coinfirstname}<span className="text-muted">{row.coinsecondname}</span></>,
                sortable: true
            },
            {
                name: "TITLE",
                selector: (row) => row.total,
                cell: (row) => <a href="#!" className=''>{row.stagefirstname}<span className="text-muted">{row.stagesecondname}</span></a>,
                sortable: true
            },
            {
                name: "DESCRIPTION",
                selector: (row) => row.startdate,
                sortable: true
            },
            {
                name: "RESPONSES",
                selector: (row) => row.enddate,
                sortable: true
            },
            {
                name: "ACTION",
                selector: (row) => { },
                sortable: true,
                cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                    <button type="submit" className="btn btn-light-success" onClick={() => handleShow(row)} data-bs-toggle="modal" data-bs-target="#icoModal">View lessons</button>
                </div>
            }
        ]
    }
    return (
        <div className="col-xl-12">
            <div className="card no-bg">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0 align-items-center">
                    <h6 className="mb-0 fw-bold ">List of surveys</h6>
                </div>
                <div className="card-body">
                    <div id="myProjectTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 mb-2 " style={{ display: 'flex',alignItems: 'center' }}>
                                <div id="myDataTable_filter" className="dataTables_filter">
                                    <label className='d-flex align-items-center'>Search:<div className='mx-1'><input type="search" className="form-control" /></div></label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <DataTable
                                    columns={columns()}
                                    data={table_row}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    highlightOnHover={true}
                                />
                            </div>
                        </div>
                        <div className="row mt-3 ">
                            <div className="col-sm-12 col-md-5" style={{ float: 'left' }}>
                                <div className="dataTables_info" id="myDataTable_info" role="status" aria-live="polite">Showing 1 to 7 of 7 entries</div>
                            </div>
                            <div className="col-sm-12 col-md-7 " >
                                <div className="dataTables_paginate paging_simple_numbers" id="myDataTable_paginate" style={{ float: 'right' }}>
                                    <ul className="pagination">
                                        <li className="paginate_button page-item previous disabled" id="myDataTable_previous"><a href="#!" className="page-link">Previous</a></li>
                                        <li className="paginate_button page-item active"><a href="#!" className="page-link">1</a></li>
                                        <li className="paginate_button page-item next disabled" id="myDataTable_next"><a href="#!" className="page-link">Next</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal fade" id="icoModal" show={iseditmodal} onHide={() => { setiseditmodal(false) }} fullscreen={fullscreen}>
                <Modal.Dialog className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <Modal.Header className="modal-header" closeButton>
                            <h5 className="modal-title">Course lessons</h5>
                        </Modal.Header>
                        <Modal.Body className="modal-body custom_setting">
                            <div className="d-flex">
                                {displayCreateLesson ? '' : <button data-step-action="next" className="btn btn-primary step-btn mb-3" onClick={createLesson}>Create lesson</button>}
                            </div>
                            {
                                displayCreateLesson ?
                                    <div className="col-lg-12 mb-3">
                                        <div className="card">
                                            <div className="card-header">
                                                <h5 className="mb-0">Create course</h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="wizard-main" id="w-horizontal">
                                                    <div className="step-app">
                                                        <div className="step-content">

                                                            <div className={`step-tab-panel active `}>
                                                                <form>
                                                                    <div className="row">
                                                                        <div className="col-md-4 col-12 mb-3">
                                                                            <label className="col-form-label">Title</label>
                                                                            <input type="text" className="form-control" placeholder="Accounting fundamentals" />
                                                                        </div>
                                                                        <div className="col-md-4 col-12 mb-3">
                                                                            <label className="col-form-label">Description</label>
                                                                            <input type="text" className="form-control" placeholder="Something..." />
                                                                        </div>
                                                                        <div className="col-md-4 col-12 mb-3">
                                                                            <label className="col-form-label">Category</label>
                                                                            <input type="text" className="form-control" placeholder="Finance" />
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="step-footer d-flex">
                                                            <button className="btn btn-danger" style={{ color: 'white' }} onClick={closeCreateLessonForm}>Close</button>
                                                            <button data-step-action="next" className="btn btn-primary step-btn">Create</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : ''
                            }
                            {/* <LessonsList /> */}
                        </Modal.Body>
                    </div>
                </Modal.Dialog>
            </Modal>
        </div>
    )
}
export default SurveyList;