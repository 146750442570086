import styled from "styled-components";

export const SubjectContainer = styled.section`
  background-color: #ffffff;
  border-radius: 10px;
  font-family: "Segoe UI", sans-serif;
  font-size: 16px;
  margin-top: 20px;
  padding: 16px;
`;

export const TextHeader = styled.h3`
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 5px;
`;

export const SubHeader = styled.h4`
  font-weight: bold;
  font-size: 17px;
`;

export const Text = styled.p`
  letter-spacing: 0;
`;

export const HRule = styled.hr`
  border: none;
  background-color: #707070;
  height: 1px;
  margin: 12px -16px;
`;

export const Attachments = styled.h3`
  letter-spacing: 0;
  color: #707070;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const TArea = styled.textarea`
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 1.28rem;
  border: 1px solid #707070;
  margin-bottom: 2rem;
`;
