import styled from "styled-components";

export const BusinessWrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  margin-top: 20px;

  .nav-item {
    .nav-link {
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: #707070;

      &:hover {
      }
    }

    .active {
      color: var(--primary-color);
      border-top: solid 3px var(--primary-color);
    }
  }
`;
export const Header = styled.h3`
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 16px;
`;

export const TabsText = styled.h5``;

export const HRule = styled.hr`
  height: 1px;
  border: none;
  background-color: #707070;
  margin: 5px -16px;
`;
