import * as yup from "yup";

export const schema = yup.object().shape({
  title: yup
    .string()
    .min(6, "Title is too short")
    .required("Title is required"),
  description: yup
    .string()
    .min(6, "Description is too short")
    .required("Description is required"),
  duration: yup.number().required("Duration is required"),
  video_url: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("Please enter website"),
});
