import React from 'react';
import BalanceDetail from '../../Component/DashboardOne/BalanceDetail';
import ChartSmallCard from '../../Component/DashboardOne/ChartSmallCard';
import UserDetailCard from '../../Component/DashboardOne/UserDetailcard';

function Dashboard({ userData }) {
  return (
    <div className='container-xxl'>
      <UserDetailCard userData={userData} />
      <ChartSmallCard />
      <BalanceDetail />
    </div>
  );
}

export default Dashboard;
