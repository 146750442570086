import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { POSTS } from "../../../constants";

const postsResultsAdapter = createEntityAdapter();

const initialState = postsResultsAdapter.getInitialState();

export const postsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPostsList: build.query({
      query: () => "/post/list",
      transformResponse: (rawResult) => {
        return postsResultsAdapter.setAll(
          initialState,
          rawResult?.response?.data
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: POSTS, id })),
              { type: POSTS, id: "LIST" },
            ]
          : [{ type: POSTS, id: "LIST" }],
    }),
    getPostById: build.query({
      query: (id) => `/post/${id}`,
      transformResponse: (rawResult) => rawResult?.response,
    }),
    addPost: build.mutation({
      query: (post) => {
        const formData = new FormData();
        for (const postKey in post) {
          formData.append(postKey, post[postKey]);
        }
        return {
          url: "/post/create",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: POSTS, id: "LIST" }],
    }),
    deletePost: build.mutation({
      query: (id) => ({
        url: `/post/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: POSTS, id: arg.id }],
    }),
    updatePost: build.mutation({
      query: (post) => {
        const formData = new FormData();
        for (const postKey in post.item) {
          formData.append(postKey, post.item[postKey]);
        }
        return {
          url: `/post/${post.id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: POSTS, id: arg.id }],
    }),
  }),
});

export const {
  useGetPostsListQuery,
  useGetPostByIdQuery,
  useAddPostMutation,
  useDeletePostMutation,
  useUpdatePostMutation,
} = postsApiSlice;
