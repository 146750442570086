import AE from "../../../assets/images/coin/AE.png";
import BLOCK from "../../../assets/images/coin/BLOCK.png";

export const UpcommingListData = {
  columns: [
    {
      name: "PROJECT",
      selector: (row) => row.name,
      cell: (row) => (
        <>
          <img src={row.image} alt="" className="img-fluid avatar mx-1" />{" "}
          {row.coinfirstname}
          <span className="text-muted">{row.coinsecondname}</span>
        </>
      ),
      sortable: true,
    },
    {
      name: "STAGE",
      selector: (row) => row.total,
      sortable: true,
      cell: (row) => (
        <a href="#!" className="">
          {row.stagefirstname}
          <span className="text-muted">{row.stagesecondname}</span>
        </a>
      ),
    },
    {
      name: "START DATE",
      selector: (row) => row.startdate,
      sortable: true,
    },
    {
      name: "END DATE",
      selector: (row) => row.enddate,
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (row) => row.btcvalue,
      cell: (row) => (
        <button
          type="submit"
          className="btn btn-light-success"
          data-bs-toggle="modal"
          data-bs-target="#icoModal">
          Add lesson
        </button>
      ),
      sortable: true,
    },
  ],
  rows: [
    {
      image: AE,
      coinfirstname: "",
      coinsecondname: "Financial management",
      stagefirstname: "Finance",
      stagesecondname: "",
      startdate: "6",
      enddate: "10",
      goal: "$100,000",
    },
    {
      image: BLOCK,
      coinfirstname: "",
      coinsecondname: "IT Infrastructure ffffffccccccccccccc",
      stagefirstname: "Technology",
      stagesecondname: "",
      startdate: "4",
      enddate: "12",
      goal: "$110,000",
    },
  ],
};
