export const faqColumns = () => {
  return [
    {
      name: "Question",
      selector: (row) => row?.question,
      sortable: true,
    },
    {
      name: "Answer",
      selector: (row) => row?.answer,
      sortable: true,
    },
  ];
};
