import styled from "styled-components";

export const DetailsWrapper = styled.section``;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  border-radius: 10px;
  border: 2px solid var(--border-color);
  padding: 4px;
  margin: 30px 0;
`;

export const PButton = styled.p`
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
  padding: 12px 10px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  margin: 0;

  &:hover {
    background-color: #e6e6e6;
  }

  &.active {
    background-color: var(--primary-color);
    color: white;
  }
`;
