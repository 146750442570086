import React from "react";
// import StartBattel from '../../Component/Lenders/StartBattel';
import WinningStreak from "../../Component/Battel/WinningStreak";
// import MyResult from '../../Component/Lenders/MyResult';
// import LiveStatus from '../../Component/Lenders/LiveStatus';
import PageTitle from "../../Component/Comman/PageTitle";
// import BattleChart from '../../Component/Lenders/BattleChart';

function Lenders() {
  return (
    <>
      <div className="body-header border-bottom d-flex py-3 mb-3">
        <PageTitle
          pagetitle="Lenders"
          downloadbutton="Download"
          reportbutton="Generate Report"
        />
      </div>
      <div className="container-xxl">
        {/* <div className='row g-3 mb-3 row-deck'>
                    <BattleChart />
                </div> */}
        <div className="row g-3 mb-3 row-deck">
          {/* <StartBattel /> */}
          <WinningStreak />
        </div>
        {/* <div className='row g-3 mb-3 row-deck'>
                    <MyResult />
                    <LiveStatus />
                </div> */}
      </div>
    </>
  );
}

export default Lenders;
