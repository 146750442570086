import React, { useState } from "react";
import { useGetUserQuery } from "../../Redux/features/user/userApiSlice";
import { useAddFaqMutation } from "../../Redux/features/faqs/faqsApiSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { schema } from "./create-faq-schema";
import { Spinner, Toast } from "react-bootstrap";
import Form from "react-bootstrap/Form";

function CreateFaq({ onCreateCancel }) {
  const { data: user } = useGetUserQuery();
  const [addFaq, { isLoading, isSuccess }] = useAddFaqMutation();
  const [showToast, setShowToast] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmitHandler = async (data) => {
    const inputData = {
      ...data,
      created_by: user.id,
    };
    try {
      await addFaq({ ...inputData }).unwrap();
      reset();
      setErrMsg("A new faq was added");
      setShowToast(true);
    } catch (e) {
      setErrMsg("Could not save faq");
      setShowToast(true);
    }
  };

  const buttonContent = isLoading ? (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
      className="me-1"
    />
  ) : (
    "Create"
  );
  const closeForm = () => {
    onCreateCancel(false);
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0">Create faq</h5>
          </div>
          <div className="card-body">
            <div className="wizard-main" id="w-horizontal">
              <div className="step-app">
                <div className="step-content">
                  <div className={`step-tab-panel active`} data-step="step1">
                    <form
                      onSubmit={handleSubmit(onSubmitHandler)}
                      id="create-faq">
                      <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                          <label className="col-form-label">Question</label>
                          <Form.Control
                            placeholder="Input question here"
                            {...register("question")}
                          />
                          {errors.question?.message && (
                            <Form.Text>{errors.question?.message}</Form.Text>
                          )}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label className="col-form-label">Answer</label>
                          <Form.Control
                            as={"textarea"}
                            placeholder="Something..."
                            {...register("answer")}
                          />
                          {errors.answer?.message && (
                            <Form.Text>{errors.answer?.message}</Form.Text>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-danger mx-5"
                    style={{ color: "white" }}
                    onClick={closeForm}>
                    Close
                  </button>
                  <button
                    disabled={!isDirty || !isValid}
                    type={"submit"}
                    form={"create-faq"}
                    className="btn btn-primary">
                    {buttonContent}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={true}
        style={{ position: "absolute", top: "55px", right: "20px" }}
        className={`d-flex align-items-center text-white ${
          isSuccess ? "bg-success" : "bg-danger"
        } border-0 fade show`}>
        <Toast.Body>{errMsg}</Toast.Body>
        <button
          type="button"
          className="btn-close btn-close-white ms-auto me-2"
          data-dismiss="toast"
          aria-label="Close"
        />
      </Toast>
    </>
  );
}

export default CreateFaq;
