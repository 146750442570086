import React, { createRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { schema } from "./create-course-schema";
import { useAddNewCourseMutation } from "../../../Redux/features/courses/coursesApiSlice";
import { Spinner, Toast } from "react-bootstrap";

const CreateCourseFrom = ({ onCreateCancel, categories }) => {
  const posterRef = createRef();
  const [addNewCourse, { isLoading, isSuccess }] = useAddNewCourseMutation();
  const closeForm = () => {
    onCreateCancel(false);
  };

  const [showToast, setShowToast] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmitHandler = async (data) => {
    try {
      await addNewCourse({
        ...data,
        poster: data.poster[0],
      }).unwrap();
      reset();
      setErrMsg("A new course was added");
      setShowToast(true);
    } catch (e) {
      console.log(e);
      setErrMsg("Could not save course");
      setShowToast(true);
    }
  };

  const buttonContent = isLoading ? (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
      className="me-1"
    />
  ) : (
    "Create"
  );

  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0">Create course</h5>
          </div>
          <div className="card-body">
            <div className="wizard-main" id="w-horizontal">
              <div className="step-app">
                <div className="step-content">
                  <div className={`step-tab-panel active`} data-step="step1">
                    <form
                      onSubmit={handleSubmit(onSubmitHandler)}
                      id="create-course">
                      <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                          <label className="col-form-label">Name</label>
                          <Form.Control
                            placeholder="Accounting fundamentals"
                            {...register("name")}
                          />
                          {errors.name?.message && (
                            <Form.Text>{errors.name?.message}</Form.Text>
                          )}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label className="col-form-label">
                            Select Category
                          </label>
                          <Form.Select
                            {...register("category")}
                            onChange={(e) =>
                              setValue("category", e.target.value, {
                                shouldValidate: true,
                              })
                            }
                            aria-label="Category">
                            <option value="">Please select category</option>
                            {categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </Form.Select>
                          {errors.category?.message && (
                            <Form.Text>{errors.category?.message}</Form.Text>
                          )}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label className="col-form-label">Description</label>
                          <Form.Control
                            as={"textarea"}
                            placeholder="Something..."
                            {...register("description")}
                          />
                          {errors.description?.message && (
                            <Form.Text>{errors.description?.message}</Form.Text>
                          )}
                        </div>
                        <div className={"col-md-6 col-12 mb-3"}>
                          <Form.Label>Upload course image</Form.Label>
                          <Form.Control
                            ref={posterRef}
                            type="file"
                            name={"poster"}
                            {...register("poster")}
                          />
                          {errors.poster?.message && (
                            <Form.Text>{errors.poster?.message}</Form.Text>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="step-footer d-flex">
                  <button
                    className="btn btn-danger"
                    style={{ color: "white" }}
                    onClick={closeForm}>
                    Close
                  </button>
                  <button
                    disabled={!isDirty || !isValid}
                    type={"submit"}
                    form={"create-course"}
                    className="btn btn-primary step-btn">
                    {buttonContent}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={true}
        style={{ position: "absolute", top: "55px", right: "20px" }}
        className={`d-flex align-items-center text-white ${
          isSuccess ? "bg-success" : "bg-danger"
        } border-0 fade show`}>
        <Toast.Body>{errMsg}</Toast.Body>
        <button
          type="button"
          className="btn-close btn-close-white ms-auto me-2"
          data-dismiss="toast"
          aria-label="Close"
        />
      </Toast>
    </>
  );
};

export default CreateCourseFrom;
