import React, { useState } from "react";
import { useGetPostsListQuery } from "../../Redux/features/posts/postsApiSlice";
import CreatePost from "./CreatePost";
import PostsList from "./PostsList";
import {
  selectAllCategories,
  useGetCategoriesQuery,
} from "../../Redux/features/categories/categoriesSlice";
import { useSelector } from "react-redux";
import { getPostRows } from "./postColumns";
import PageTitle from "../../Component/Comman/PageTitle";

function Posts() {
  let table_row = [];
  const { isLoading, isSuccess, isError, error, data } = useGetPostsListQuery();
  const {
    isLoading: loading,
    isSuccess: success,
    isError: isCategoryError,
    error: categoryError,
  } = useGetCategoriesQuery();
  const categories = useSelector(selectAllCategories);

  if (isLoading) {
  } else if (isSuccess && success) {
    const { ids, entities } = data;
    const postsList = ids
      .map((id) => entities[id])
      .reduce((acc, curr) => [...acc, curr], []);
    table_row = getPostRows(postsList, categories);
  } else if (isError || isCategoryError) {
    console.log(error);
    console.log(categoryError);
  }

  const [displayCreate, setDisplayCreate] = useState(false);

  const createPost = () => {
    setDisplayCreate(true);
  };

  const closeForm = () => {
    setDisplayCreate(false);
  };

  return (
    <>
      <div className="body-header border-bottom d-flex py-3 mb-3">
        <PageTitle pagetitle="Posts" />
      </div>
      <div className="container-xxl">
        {displayCreate ? (
          <div className="row mb-3">
            <CreatePost onCreateCancel={closeForm} categories={categories} />
          </div>
        ) : (
          <div className="d-flex">
            <button className="btn btn-primary mb-2" onClick={createPost}>
              Add Post
            </button>
          </div>
        )}
        <div className="row">
          <PostsList
            table_row={table_row}
            isLoading={isLoading || loading}
            categories={categories}
          />
        </div>
      </div>
    </>
  );
}

export default Posts;
