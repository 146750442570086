import React, { useEffect, useState } from "react";
import { useUpdatePostMutation } from "../../Redux/features/posts/postsApiSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { schema } from "./create-post-schema";
import { Spinner, Toast } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

function EditPost({ onClose, show, post, categories }) {
  const [updatePost, { isLoading, isSuccess }] = useUpdatePostMutation();
  const [showToast, setShowToast] = useState(false);
  const defaultData = {
    content: post.content,
    summary: post.summary,
  };
  const [errMsg, setErrMsg] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      content: "",
      summary: "",
    },
    mode: "onChange",
  });
  const closeForm = () => {
    onClose(false);
  };
  const onSubmitHandler = () => {};

  useEffect(() => {
    reset(defaultData);
  }, [post]);

  const buttonContent = isLoading ? (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
      className="me-1"
    />
  ) : (
    "Update Post"
  );

  return (
    <>
      <Modal
        size="xl"
        show={show}
        onHide={() => onClose(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Edit Lesson
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmitHandler)} id="create-post">
            <div className="row">
              <div className="col-md-6 col-12 mb-3">
                <label className="col-form-label">Title</label>
                <Form.Control
                  placeholder="Accounting fundamentals"
                  {...register("summary")}
                />
                {errors.summary?.message && (
                  <Form.Text>{errors.summary?.message}</Form.Text>
                )}
              </div>
              <div className="col-md-6 col-12 mb-3">
                <label className="col-form-label">Select Category</label>
                <Form.Select
                  {...register("category_id")}
                  onChange={(e) =>
                    setValue("category_id", e.target.value, {
                      shouldValidate: true,
                    })
                  }
                  aria-label="Category">
                  <option value="">Please select category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
                {errors.category?.message && (
                  <Form.Text>{errors.category_id?.message}</Form.Text>
                )}
              </div>
              <div className="col-md-6 col-12 mb-3">
                <label className="col-form-label">Description</label>
                <Form.Control
                  as={"textarea"}
                  placeholder="Something..."
                  {...register("content")}
                />
                {errors.content?.message && (
                  <Form.Text>{errors.content?.message}</Form.Text>
                )}
              </div>
            </div>
          </form>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-danger"
              style={{ color: "white" }}
              onClick={closeForm}>
              Close
            </button>
            <button
              disabled={!isDirty || !isValid}
              type={"submit"}
              form={"create-post"}
              className="btn btn-primary">
              {buttonContent}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={true}
        style={{ position: "absolute", top: "55px", right: "20px" }}
        className={`d-flex align-items-center text-white ${
          isSuccess ? "bg-success" : "bg-danger"
        } border-0 fade show`}>
        <Toast.Body>{errMsg}</Toast.Body>
        <button
          type="button"
          className="btn-close btn-close-white ms-auto me-2"
          data-dismiss="toast"
          aria-label="Close"
        />
      </Toast>
    </>
  );
}

export default EditPost;
