import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { lessonColumns } from "./LessonsColumns";
import { useDeleteLessonMutation } from "../../../Redux/features/lessons/lessonsApiSlice";
import EditLesson from "./EditLesson";
import { Spinner } from "react-bootstrap";

const LessonsList = ({ table_row, isLoading }) => {
  const [isEditModal, setIsEditModal] = useState(false);
  const [deleteLesson, { isLoading: loading }] = useDeleteLessonMutation();
  const [selectedLesson, setSelectedLesson] = useState({});
  const [deleteRow, setDeleteRow] = useState({});

  const handleShow = (row) => {
    setSelectedLesson(row);
    setIsEditModal(true);
  };

  const onDeleteLesson = async (row) => {
    setDeleteRow(row);
    try {
      await deleteLesson(row.id).unwrap();
    } catch (e) {
      setDeleteRow({});
      console.log(e);
    }
  };

  const buttonContent = (row) => {
    if (loading && row.id === deleteRow.id) {
      return (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className="me-1"
        />
      );
    }
    return "Delete";
  };

  const editButton = {
    name: "ACTION",
    selector: () => {},
    sortable: true,
    cell: (row) => (
      <button
        type="submit"
        className="btn btn-light-success"
        onClick={() => handleShow(row)}>
        Edit lesson
      </button>
    ),
  };

  const deleteButton = {
    name: "ACTION",
    selector: () => {},
    sortable: true,
    cell: (row) => (
      <button
        className="btn btn-light-danger"
        onClick={() => onDeleteLesson(row)}>
        {buttonContent(row)}
      </button>
    ),
  };

  return (
    <>
      <div className="col-xl-12">
        <div className="card no-bg">
          <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0 align-items-center">
            <h6 className="mb-0 fw-bold ">List of lessons</h6>
          </div>
          <div className="card-body">
            <div
              id="myProjectTable_wrapper"
              className="dataTables_wrapper dt-bootstrap5 no-footer">
              <div className="row">
                <div
                  className="col-sm-12 col-md-6 mb-2 "
                  style={{ display: "flex", alignItems: "center" }}>
                  <div id="myDataTable_filter" className="dataTables_filter">
                    <label className="d-flex align-items-center">
                      Search:
                      <div className="mx-1">
                        <input type="search" className="form-control" />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <DataTable
                    columns={[...lessonColumns(), editButton, deleteButton]}
                    data={table_row}
                    defaultSortField="title"
                    pagination
                    selectableRows={false}
                    highlightOnHover={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditLesson
        show={isEditModal}
        onClose={setIsEditModal}
        lesson={selectedLesson}
      />
    </>
  );
};

export default LessonsList;
